import { FC, Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router";
import addIcon from "../../../../assets/images/add.svg";
import defaultAvatar from "../../../../assets/images/default-avatar.png";
import Config from "../../../../shared/models/config";
import { ITask, ITaskHistory, Task, TaskHistory } from "../../../../shared/models/task";
import TaskCantDrag from "../index";
import AddSubTask from "./add-sub-task/index";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@mui/material/Collapse";
import "./style.scss";
import { IStatus } from "../../../../shared/models/status";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { checkPermissionChangeTask } from "../../../../shared/permissions";
import { IUserInfo } from "../../../../shared/models/userInfo";
import { pendingProcessingTaskUpdate, updateTask, updateTaskFailed } from "../../../../redux/actions/task.action";
import { toast } from "react-toastify";
import { checkRequirementsChangeStatusField, convertLink } from "../../../../util";
import ZoomImage from "../zoom-image";
import { checkAllChildTasksAreDone } from "../../Column";
import { IUserGroup } from "../../../../shared/models/userGroup";
import MyTextBox from "../../widgets/MyTextBox";
import FormControl from "react-bootstrap/FormControl";
import moment from "moment";
import { ICampaign } from "../../../../shared/models/campaign";
import { updateCampaigns } from "../../../../redux/actions/campaign.action";
import { validatePoint } from "../edit-task-modal/confirmed-points";
import TrendingFlatOutlinedIcon from "@mui/icons-material/TrendingFlatOutlined";
import MyAvatar from "../../../avatar";

const SubTasksPreview: FC<{
    parentTask: ITask;
    location: string;
    onSelectedTask: (task: ITask) => void;
    onShowMoveTaskModal?: (value: boolean) => void;
    checkedItems?: { [key: string]: boolean };
    onChange?: (subtaskId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ parentTask, location, onSelectedTask, onShowMoveTaskModal, onChange, checkedItems }) => {
    const dispatch = useDispatch();
    const allTasks: ITask[] = useSelector((state: any) => state.taskState.tasks ?? []);
    const campaigns: ICampaign[] = useSelector((state: any) => state.campaignState.campaigns ?? []);
    const subTasks = allTasks.filter((t) => t.parentId == parentTask._id);
    const [showAddSubTask, setShowAddSubTask] = useState(false);
    const [progress, setProgress] = useState(0);
    const [expandTask, setExpandTask] = useState(-1);
    const [expandSubTask, setExpandSubTask] = useState(-1);
    const allStatus: IStatus[] = useSelector((state: any) => state.statusState.status ?? []);
    const mapStatuses: Map<string, IStatus> = new Map();
    for (let status of allStatus) {
        mapStatuses.set(status._id, status);
    }
    const processing: boolean = useSelector((state: any) => state.taskState.processing ?? false);
    const [savingTask, setSavingTask] = useState(false);
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    useEffect(() => {
        // Add sub task vao campaign task cha
        let listCampaignsUpdate: ICampaign[] = [];
        const parentCampaigns = campaigns.filter((c) => c.listTaskIDs.includes(parentTask._id));
        for (const campaign of parentCampaigns) {
            for (const subTask of subTasks) {
                if (!campaign.listTaskIDs.includes(subTask._id)) {
                    campaign.listTaskIDs.push(subTask._id);
                    listCampaignsUpdate.push(campaign);
                }
            }
        }
        if (listCampaignsUpdate.length) {
            dispatch(updateCampaigns(listCampaignsUpdate));
        }

        let nbSubTasks = 0;
        let nbSubTasksDone = 0;
        subTasks.map((task) => {
            nbSubTasks++;
            if (task.statusId === Config.COLUMN_STATUS.DONE.mongoId) {
                nbSubTasksDone++;
            }
        });
        setProgress(nbSubTasksDone / nbSubTasks);
    }, [subTasks]);
    if (location === "board") {
        return (
            <div className="subtasks">
                {subTasks.map((subTask) => (
                    <Fragment key={subTask._id}>
                        <TaskCantDrag
                            task={subTask}
                            onSelectedTask={() => {
                                // history.push({
                                //     pathname: "/task/" + subTask._id,
                                // });
                                onSelectedTask(subTask);
                            }}
                        />
                    </Fragment>
                ))}
            </div>
        );
    }
    // const [title, setTitle] = useState("task.title");
    // const [editable, setEditable] = useState(false);
    // const [status, setStatus] = useState<any>("task.statusId");
    // const tasks: Task[] = useSelector(
    //     (state: any) => state.taskState.tasks ?? []
    // );
    // useEffect(() => {
    //     setStatus(task.statusId);
    // }, [task.statusId]);
    // const onResetUpdate = (changes: ITaskHistory[], task: ITask) => {
    //     for (let change of changes) {
    //         if (change.field === "title") {
    //             setTitle(task.title);
    //         } else if (change.field === "status") {
    //             setStatus(task.statusId);
    //         } else if (change.field === "point") {
    //             setStatus(task.point);
    //         } else if (change.field === "deadline") {
    //             setStatus(task.deadline);
    //         }
    //     }
    // };

    const getChangedInfo = (newTask: ITask, task: ITask) => {
        let changes: ITaskHistory[] = [];
        const time = new Date();
        const userID = userInfo?._id;
        if (task.title !== newTask.title) {
            changes.push(
                new TaskHistory({
                    field: "title",
                    from: task.title,
                    to: newTask.title,
                    userID,
                    time,
                })
            );
        }
        if (task.statusId !== newTask.statusId) {
            changes.push(
                new TaskHistory({
                    field: "status",
                    from: task.statusId,
                    to: newTask.statusId,
                    userID,
                    time,
                })
            );
        }
        if (task.point !== newTask.point) {
            changes.push(
                new TaskHistory({
                    field: "point",
                    from: task.point,
                    to: newTask.point,
                    userID,
                    time,
                })
            );
        }
        if (task.deadline?.getTime() !== newTask.deadline?.getTime()) {
            changes.push(
                new TaskHistory({
                    field: "deadline",
                    from: task.deadline,
                    to: newTask.deadline,
                    userID,
                    time,
                })
            );
        }
        return changes;
    };
    const onUpdateTask = (field: any, task: ITask) => {
        let { title, status, point, deadline } = field;
        if (!!!title) title = task.title;
        if (!!!status) status = task.statusId;
        if (!!!point) point = task.point;
        if (!!!deadline) deadline = task.deadline;
        if (!savingTask) {
            setSavingTask(true);
            dispatch(pendingProcessingTaskUpdate());
            setTimeout(() => {
                const taskUpdate = {
                    ...task,
                    statusId: status,
                    status: mapStatuses.get(status),
                    title: title,
                    point: point,
                    deadline: deadline,
                };
                const changes = getChangedInfo(taskUpdate, task);
                const histories = (changes ?? []).concat(task.histories ?? []);
                const newTask: ITask = {
                    ...taskUpdate,
                    histories,
                };
                if (
                    checkPermissionChangeTask({
                        user: userInfo,
                        task: task,
                        newTask,
                        onFeedBack: (msg) => {
                            // onResetUpdate(changes, task);
                            toast(() => <div>{msg}</div>, {
                                position: "bottom-center",
                                type: "error",
                            });
                            setSavingTask(false);
                            dispatch(updateTaskFailed(""));
                        },
                    })
                ) {
                    let updateStatus = !!changes.find((c) => c.field === "status");
                    dispatch(updateTask({ ...newTask, updateStatus: updateStatus }));
                    setSavingTask(false);
                    setTimeout(() => setSavingTask(false), 1000);
                }
            }, 500);
        }
    };
    return (
        <div className="subtasks">
            <div className="subtasks-title">
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "10px",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <h5 style={{ paddingRight: "10px" }}>Subtasks</h5>
                    {subTasks[0] && (
                        <div
                            style={{
                                width: " 30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: "#e7e3e3",
                                marginRight: "15px",
                            }}
                        >
                            <TrendingFlatOutlinedIcon
                                onClick={() => onShowMoveTaskModal && onShowMoveTaskModal(true)}
                                titleAccess="Move Subtasks"
                                style={{ cursor: "pointer", marginTop: "2px", marginLeft: "2px" }}
                            />
                        </div>
                    )}
                </div>
                <div>
                    <img
                        className="add-icon"
                        onClick={() => {
                            if (parentTask.statusId === Config.COLUMN_STATUS.DONE.mongoId) {
                                toast(() => <div>Không tạo thêm sub task cho task đã hoàn thành!</div>, {
                                    position: "bottom-center",
                                    type: "warning",
                                });
                            } else setShowAddSubTask(true);
                        }}
                        src={addIcon}
                        title="Add sub tasks"
                    />
                </div>
            </div>
            {progress > 0 && (
                <div className="progressive-subtask">
                    <div className="now" style={{ width: `${progress * 100}%` }}></div>
                </div>
            )}
            {showAddSubTask && <AddSubTask parentTask={parentTask} onHide={() => setShowAddSubTask(false)} />}
            <div className="wrap-task">
                {subTasks
                    .sort((a, b) => a.shortId.localeCompare(b.shortId))
                    .map((task, index) => {
                        let listSubTasks: ITask[] = [];
                        let queue = [task];
                        while (queue.length > 0) {
                            let task = queue.shift();

                            let childrenTasks = allTasks.filter((t) => t.parentId == task?._id);
                            listSubTasks = [...listSubTasks, ...childrenTasks];
                            queue = [...queue, ...childrenTasks];
                        }
                        return (
                            <div style={{ borderBottom: "1px solid #eee" }} key={task._id}>
                                <MainTaskPreview
                                    onChange={onChange && onChange}
                                    checkedItems={checkedItems}
                                    task={task}
                                    index={index}
                                    expandTask={expandTask}
                                    setExpandTask={setExpandTask}
                                    allowEdit={true}
                                    onUpdateTask={(field: any) => {
                                        onUpdateTask(field, task);
                                    }}
                                    processing={processing}
                                    listSubtask={subTasks}
                                />
                                <Collapse in={index == expandTask} timeout="auto" unmountOnExit>
                                    <MainTaskContent
                                        task={task}
                                        onUpdateTask={(field: any) => {
                                            onUpdateTask(field, task);
                                        }}
                                        processing={processing}
                                    />
                                    {listSubTasks.map((nsubTask: ITask, index) => {
                                        return (
                                            <div key={index}>
                                                <div
                                                    style={{
                                                        alignItems: "center",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <MainTaskPreview
                                                        onChange={onChange && onChange}
                                                        checkedItems={checkedItems}
                                                        task={nsubTask}
                                                        index={index}
                                                        expandTask={expandSubTask}
                                                        setExpandTask={setExpandSubTask}
                                                        allowEdit={true}
                                                        isChildOfChild={true}
                                                        onUpdateTask={(field: any) => {
                                                            onUpdateTask(field, nsubTask);
                                                        }}
                                                        processing={processing}
                                                    />
                                                </div>
                                                <Collapse in={index == expandSubTask} timeout="auto" unmountOnExit>
                                                    <MainTaskContent
                                                        task={nsubTask}
                                                        onUpdateTask={(field: any) => {
                                                            onUpdateTask(field, nsubTask);
                                                        }}
                                                        processing={processing}
                                                    />
                                                </Collapse>
                                            </div>
                                        );
                                    })}
                                </Collapse>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default SubTasksPreview;

const MainTaskPreview = ({
    task,
    index,
    expandTask,
    setExpandTask = (v) => {},
    allowEdit,
    isChildOfChild = false,
    onUpdateTask,
    processing,
    onChange,
    checkedItems,
    listSubtask
}: {
    task: ITask;
    index: number;
    expandTask?: number;
    setExpandTask?: (v: number) => void;
    allowEdit: boolean;
    isChildOfChild?: boolean;
    onUpdateTask: any;
    processing: boolean;
    checkedItems?: { [key: string]: boolean };
    onChange?: (subtaskId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    listSubtask? : ITask[];
}) => {
    const allStatus: IStatus[] = useSelector((state: any) => state.statusState.status ?? []);
    const mapStatuses: Map<string, IStatus> = new Map();
    for (let status of allStatus) {
        mapStatuses.set(status._id, status);
    }
    const [title, setTitle] = useState(task.title);
    const [editable, setEditable] = useState(false);
    const [status, setStatus] = useState<any>(task.statusId);
    const [savingTask, setSavingTask] = useState(false);
    // const userInfo: IUserInfo = useSelector(
    //     (state: any) => state.authState.user
    // );
    const tasks: Task[] = useSelector((state: any) => state.taskState.tasks ?? []);
    useEffect(() => {
        setStatus(task.statusId);
    }, [task.statusId]);

    return (
        <div>
            <div className="task" key={task._id}>
                <div
                    style={{
                        marginLeft: !isChildOfChild ? 0 : "52px",
                        marginRight: "16px",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    {listSubtask?.find((t) => t._id == task?._id) && <input
                        style={{marginRight:"10px"}}
                        type="checkbox"
                        checked={(checkedItems && checkedItems[task?._id]) || false}
                        onChange={onChange && onChange(task?._id)}
                    />}
                    {isChildOfChild ? <SubdirectoryArrowRightIcon /> : <></>}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <a className="subtask-shortId" href={"/task/" + task._id} target="_blank">
                            {task.shortId}
                        </a>
                        {savingTask ? <span>saving...</span> : <></>}
                    </div>

                    {task.point ? (
                        <div style={{ marginTop: "3px" }}>
                            <div className="point">{task.point}</div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {editable && allowEdit ? (
                        <input
                            type="text"
                            value={title}
                            autoFocus={editable}
                            disabled={
                                task.status?._id === Config.COLUMN_STATUS.DONE.mongoId ||
                                task.status?._id === Config.COLUMN_STATUS.PROD_VALIDATION.mongoId ||
                                processing
                            }
                            onBlur={(e) => {
                                setEditable(false);
                                if (title != task.title) {
                                    onUpdateTask({ title: title }, task);
                                }
                            }}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            style={{ maxWidth: "300px", width: "100%" }}
                        />
                    ) : (
                        <div
                            className="subtask-title"
                            onClick={() => {
                                setEditable(true);
                                setTitle(task.title);
                            }}
                        >
                            {task.title}
                        </div>
                    )}
                </div>

                <div
                    style={{
                        alignItems: "center",
                    }}
                >
                    <div className="subtask-assignee-avatar">
                        <MyAvatar url={task.assignee?.avatar} />
                    </div>
                    <div>
                        {task.deadline && (
                            <div
                                style={{
                                    fontSize: "12px",
                                    fontStyle: "italic",
                                }}
                            >
                                <AvTimerIcon
                                    style={{
                                        width: "17px",
                                        height: "17px",
                                    }}
                                />{" "}
                                {task.deadline?.toLocaleDateString()}
                            </div>
                        )}
                        <div
                            className="subtask-status"
                            style={{
                                backgroundColor: task.status?.color,
                            }}
                        >
                            {allowEdit ? (
                                <select
                                    disabled={processing}
                                    className="select-status"
                                    onChange={(e) => {
                                        if (e.target.value !== task.status?._id) {
                                            if (!checkRequirementsChangeStatusField(task, e.target.value)) return;
                                            let allowEdit = true;
                                            if (e.target.value === Config.COLUMN_STATUS.DONE.mongoId) {
                                                allowEdit = checkAllChildTasksAreDone(task, tasks);
                                            }
                                            if (allowEdit) {
                                                onUpdateTask(
                                                    {
                                                        status: e.target.value,
                                                    },
                                                    task
                                                );
                                                setStatus(e.target.value);
                                            }
                                        }
                                    }}
                                    value={status}
                                >
                                    {allStatus.map((s: IStatus) => (
                                        <option key={s._id} value={s._id}>
                                            {s.title}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                task.status?.title
                            )}
                        </div>
                    </div>

                    <div
                        className={"expand-task " + (expandTask == index ? "expanded" : "")}
                        onClick={() => {
                            if (expandTask != index) setExpandTask(index);
                            else setExpandTask(-1);
                        }}
                    >
                        <ExpandMore />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MainTaskContent = memo(({ task, onUpdateTask, processing }: { task: ITask; onUpdateTask: any; processing: boolean }) => {
    const [zoomImageUrl, setZoomImageUrl] = useState("");
    const [points, setPoints] = useState(task.point);
    const [deadline, setDeadline] = useState(task.deadline);
    const [deadlineTmp, setDeadlineTmp] = useState(task.deadline);
    const handlePointsChange = (value: any) => {
        setPoints(value);
        onUpdateTask({ point: value });
    };
    const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups);
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const isLeaderGroup = (userId: string) => {
        let res = false;
        userGroups.forEach((group) => {
            group.users.forEach((user) => {
                if (user.userId === userId && user.role === "lead") {
                    if (task.projectId === group.projectId)
                        //la lead cua group thuoc project task
                        res = true;
                }
            });
        });
        return res;
    };

    let taskSprint = task.sprint;
    return (
        <>
            {zoomImageUrl && <ZoomImage url={zoomImageUrl} closeZoomImage={() => setZoomImageUrl("")} />}
            <div className="main-task-content">
                <div className="bold">&#x2022; Description:</div>
                <div
                    style={{ marginLeft: "8px" }}
                    dangerouslySetInnerHTML={{
                        __html: convertLink(task.description),
                    }}
                    onClick={(event) => {
                        let e = event.target as HTMLElement;
                        const localName = e.localName;
                        event.preventDefault();
                        event.stopPropagation();
                        if (e.classList.contains("link")) {
                            window.open(e.innerText);
                            return;
                        }
                        if (localName === "a") {
                            const href = e.getAttribute("href");
                            href && window.open(href, "_blank");
                            return;
                        }
                        if (localName === "img") {
                            const imageUrl = e.getAttribute("src");
                            imageUrl && setZoomImageUrl(imageUrl);
                            return;
                        }
                    }}
                />

                <div className="sub-task-user">
                    <span className="bold">&#x2022; Lead Dev: </span>
                    <span>
                        {task.leadDev ? (
                            <div className="user-container">
                                <div className="subtask-assignee-avatar">
                                    <MyAvatar url={task.leadDev?.avatar} />
                                </div>
                                {task.leadDev?.lastName + " " + task.leadDev?.firstName}
                            </div>
                        ) : (
                            ""
                        )}
                    </span>
                </div>
                <div className="sub-task-user">
                    <span className="bold">&#x2022; Tester: </span>
                    <span>
                        {task?.testers ? (
                            <div
                                style={{
                                    display: "flex",
                                    marginLeft: "8px",
                                    flexDirection: "column",
                                }}
                            >
                                {task?.testers?.map((infoTester: any, index: number) => (
                                    <div key={index} className="user-container">
                                        <div className="subtask-assignee-avatar">
                                            <img src={infoTester?.avatar ? infoTester?.avatar : defaultAvatar} />
                                        </div>
                                        {infoTester?.lastName + " " + infoTester?.firstName}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            ""
                        )}
                    </span>
                </div>
                <div className="sub-task-user">
                    <span className="bold">&#x2022; Point : </span>
                    <div
                        className="user-container"
                        style={{
                            marginTop: "8px",
                            maxWidth: "100px",
                            width: "100%",
                        }}
                    >
                        <MyTextBox
                            text={points + ""}
                            title="point"
                            disabled={
                                processing ||
                                (points === undefined || points === null
                                    ? false
                                    : !(
                                          isLeaderGroup(userInfo._id) || //leader group
                                          task?.reporterId === //reporter
                                              userInfo._id ||
                                          userInfo.role === //leader all
                                              Config.USER_ROLE_LEADER ||
                                          userInfo.role === //leader all
                                              Config.USER_ROLE_ADMIN
                                      ))
                            }
                            onChange={(value: any) => {
                                let _val: any;
                                try {
                                    _val = parseInt(value);
                                } catch (e) {}
                                if (!Number.isNaN(_val)) {
                                    if (!validatePoint(_val)) {
                                        return;
                                    }
                                    handlePointsChange(value);
                                }
                            }}
                            type="number"
                        />
                    </div>
                </div>
                <div className="sub-task-user">
                    <span className="bold">&#x2022; Deadline: </span>
                    <div
                        className="user-container"
                        style={{
                            marginTop: "8px",
                            maxWidth: "300px",
                            width: "100%",
                        }}
                    >
                        <FormControl
                            type="datetime-local"
                            name="deadline"
                            value={moment(deadlineTmp).format("YYYY-MM-DDTHH:mm")}
                            min={moment(new Date().setHours(0, 0, 0, 0)).format("YYYY-MM-DDTHH:mm")}
                            max={moment(taskSprint?.endDate.setHours(23, 59, 59, 0)).format("YYYY-MM-DDTHH:mm")}
                            onChange={(e) => {
                                setDeadlineTmp(new Date(e.target.value));
                            }}
                            onBlur={(e) => {
                                setDeadline(deadlineTmp);
                                onUpdateTask({ deadline: deadlineTmp });
                            }}
                            disabled={
                                ((deadline != null || deadline != undefined
                                    ? !(
                                          isLeaderGroup(userInfo._id) || //leader group
                                          task?.reporterId === //reporter
                                              userInfo._id ||
                                          userInfo.role === //leader all
                                              Config.USER_ROLE_LEADER ||
                                          userInfo.role === //leader all
                                              Config.USER_ROLE_ADMIN
                                      )
                                    : false) &&
                                    task.type.toLowerCase() !== "sub task") ||
                                processing
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
});
