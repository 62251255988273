import axios from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { TASK_FIELDS } from "../../components/dashboard/task-preview/edit-task-modal/new_edit_task";
import Constants from "../../constant";
import Config from "../../shared/models/config";
import { ISprint, Sprint } from "../../shared/models/sprint";
import { ISprintReport, SprintReport } from "../../shared/models/sprintReport";
import { ITask, ITaskHistory, TaskHistory } from "../../shared/models/task";
import { IUserInfo, UserCoefficient } from "../../shared/models/userInfo";
import Url from "../../util/url";
import { setSprintActiveUser } from "../actions/report.action";
import { SprintTypes, getAllSprintFailed, getAllSprintSuccess } from "../actions/sprint.action";
import {
    ISprintReportAction,
    SprintReportTypes,
    getRewardMinusExpSuccess,
    getSprintExpDataFailed,
    getSprintExpDataSuccess,
    updateSprintReportFailed,
    updateSprintReportSuccess,
} from "../actions/sprintReport.action";
import { reload } from "../actions/web.action";
import { updateUserScoreAPI } from "../api/report.api";
import { getAllTaskAPI } from "./task.saga";
import { timezoneSetHours } from "../../util";
import { IOffDaysWfhDaysState } from "../reducers/workingTime.reducer";
import { getAllTaskSuccess } from "../actions/task.action";
import { IUserExpectation, UserExpectation } from "../../shared/models/userExpectation";
import { getAllExpectationsSuccess } from "../actions/userExpectation.action";
import { converDisciplineDataToReport, convertTaskToReport, roleForPoint } from "../../shared/utils";
import { IStatus } from "../../shared/models/status";
import { IProject } from "../../shared/models/project";
import { preHandleTasks } from "../../utils";
import { IWorkingTime } from "../../shared/models/workingTime";
import { IOffDaysWfhDays } from "../../shared/models/offDaysWfhDays";

async function getAllSprintAPI(): Promise<ISprint[] | null> {
    return await axios.get(Url("sprint")).then((data: any) => {
        if (data?.data?.success && data?.data?.response) {
            return data.data.response.map((item: any) => {
                return new Sprint(item);
            });
        }
        return null;
    });
}

function* getAllSprintSaga() {
    try {
        const sprints: ISprint[] | null = yield call(getAllSprintAPI);
        if (sprints) {
            yield put(getAllSprintSuccess(sprints));
        } else {
            yield put(getAllSprintFailed("getAllSprintSaga: tasks undefined!"));
        }
    } catch (err) {
        yield put(getAllSprintFailed("getAllSprintSaga ERROR: " + err));
    }
}

async function getReportAPI(sprintID: string): Promise<ISprintReport[]> {
    return await axios.post(Url("sprint/reports"), { sprintID: sprintID }).then((res) => {
        if (res.status == 200 && res.data) {
            return res.data.map((item: any) => {
                return new SprintReport(item);
            });
        }
        return [];
    });
}

function* updateUpdateReport(action: ISprintReportAction) {
    const sprintID = action.sprint?._id;
    let currentSprint: ISprint | undefined = yield select((state: any) =>
        state.sprintState.sprints.find((s: any) => s._id == sprintID)
    );
    const users: IUserInfo[] = yield select((state: any) => state.userInfoState.userInfos);
    const sprints: ISprint[] = yield select((state: any) => state.sprintState.sprints);
    const allStatus: IStatus[] = yield select((state: any) => state.statusState.status);
    const projects: IProject[] = yield select((state: any) => state.projectState.projects);
    if (sprintID && currentSprint) {
        try {
            if (!currentSprint.activeSprint) {
                // report cu vvvv
                const list: ISprintReport[] = yield call(getReportAPI, sprintID);
                yield put(updateSprintReportSuccess(list));
                // ^^^^
            } else {
                let tasks: ITask[] = yield call(getAllTaskAPI, sprintID);
                tasks = preHandleTasks(
                    //cai tien getAllTask by Dat
                    tasks,
                    users,
                    sprints,
                    allStatus,
                    projects
                );
                yield put(getAllTaskSuccess(tasks));
                // report cu vvvv
                const _data: ISprintReport[] = yield call(
                    updateUserScoreAPI,
                    currentSprint,
                    users,
                    sprints,
                    allStatus,
                    projects,
                    false,
                    tasks
                );
                yield put(updateSprintReportSuccess(_data));
                // ^^^^
                let sprintActiveUsers: any = {};
                let sprintStart = currentSprint.startDate.getTime();
                let sprintEnd = currentSprint.endDate.getTime();
                //check user is active on sprint (do something on task) ** chi dung khi sprint active
                tasks?.forEach((task) => {
                    task?.histories?.forEach((h) => {
                        let htime = h.time.getTime();
                        let hfield = h.field;
                        if (!!task.reporter) sprintActiveUsers[task.reporter._id] = true;
                        if (!!task.leadDev) sprintActiveUsers[task.leadDev._id] = true;
                        if (!!task.testers)
                            task.testers.forEach((t) => {
                                sprintActiveUsers[t._id] = true;
                            });
                        if (htime <= sprintEnd && htime >= sprintStart) {
                            if (
                                hfield === TASK_FIELDS.point ||
                                hfield === TASK_FIELDS.testLink ||
                                hfield === TASK_FIELDS.status
                                // || true
                            ) {
                                sprintActiveUsers[h.userID] = true;
                            }
                        }
                    });
                });
                yield put(setSprintActiveUser(sprintActiveUsers));
            }
        } catch (e) {
            yield put(updateSprintReportFailed(e));
        }
    } else {
        yield put(updateSprintReportFailed("sprintId or sprint not found!"));
    }
}

async function calcRequestRatingAPI(sprintId: string) {
    let result = await axios.patch(Url("rating/calc-request-rating"), {
        sprintId,
    });
    return result.data;
}

function nextSprintName(name: string) {
    return name
        ? name
              .split(" ")
              .map((c) => {
                  let num = parseInt(c);
                  if (!isNaN(num)) {
                      return parseInt(c) + 1;
                  }
                  return c;
              })
              .join(" ")
        : "Sprint 10";
}

interface TaskUpdate {
    _id: string;
    sprint: string;
    histories: ITaskHistory[];
    point: number | undefined;
    finishedDate: number;
}

async function updateTasksAPI(tasks: TaskUpdate[]) {
    let result = await axios.post(Url("task/updates"), {
        tasks,
    });
    return result?.data?.response ?? null;
}

async function updateSprintAPI(sprint: ISprint) {
    let result = await axios.patch(Url("sprint/update"), {
        sprint,
    });
    if (result.status == 200 && result?.data?.response) {
        return new Sprint(result?.data?.response);
    }
    return null;
}
async function updateSprintTasksAPI(data: any, sprint: string) {
    let result = await axios.patch(Url("sprint-report-v2/update-sprint-tasks-for-end-sprint"), {
        data,
        sprint,
        lastUpdate: new Date().getTime(),
    });
    if (result.status == 200 && result?.data) {
        return "ok";
    }
    return "not ok";
}
export function* updateEndSprintSaga() {
    // create sprint
    const currentUser: IUserInfo = yield select((state) => state.authState.user);
    const sprints: ISprint[] = yield select((state) => state.sprintState.sprints);
    const users: IUserInfo[] = yield select((state: any) => state.userInfoState.userInfos);
    const mapUsers: { [userId: string]: IUserInfo } = {};
    const mapCoef: {
        [id: string]: {
            cWork: number;
            cDiscipline: number;
            cCreative: number;
            cLeadership: number;
            cActive: number;
            cQuality: number;
        };
    } = {};
    for (let user of users) {
        mapUsers[user._id] = user;
        mapCoef[user._id] = {
            cWork: user.cWork,
            cDiscipline: user.cDiscipline,
            cCreative: user.cCreative,
            cLeadership: user.cLeadership,
            cActive: user.cActive,
            cQuality: user.cQuality,
        };
    }
    const currentSprint = sprints.find((s) => s.activeSprint);
    if (currentSprint && currentUser) {
        // yield put(updateSprintReport(currentSprint._id, true)); // updates reports
        const currentDate = timezoneSetHours(new Date()).getTime();
        let nextSprintDate = new Date(currentDate + Constants.DAY_NUMBER_OF_SPRINT * Constants.TIMESTAMP_DATE - 1); /// 23:59:59
        let newSprint = sprints.find((s) => s.name == nextSprintName(currentSprint.name));
        if (!newSprint) {
            newSprint = new Sprint({
                name: nextSprintName(currentSprint.name),
                backLog: false,
                startDate: new Date(currentDate),
                endDate: nextSprintDate,
                activeSprint: true,
                status: Constants.STATUS_PUBLIC,
                shortId: nextSprintName(currentSprint.name)
                    .split(" ")
                    .map((c: string) => {
                        let num = parseInt(c);
                        if (!isNaN(num)) {
                            return parseInt(c);
                        }
                        return null;
                    })
                    .filter((c) => c)
                    .join(),
            });
            newSprint = yield call(updateSprintAPI, newSprint);
        } else {
            newSprint.activeSprint = true;
            newSprint = yield call(updateSprintAPI, newSprint);
        }
        currentSprint.activeSprint = false;
        currentSprint.status = Constants.STATUS_DELETED;
        yield call(updateSprintAPI, currentSprint);
        /// handle task :{calcualte exp, move to next sprint}
        const tasks: ITask[] = yield select((state) => state.taskState.tasks);
        let mapTaskID: any = {};
        for (let task of tasks) {
            mapTaskID[task._id] = task;
        }
        //report cu // let mapUserTaskFinished: any = {};
        let tasksUpdate: TaskUpdate[] = [];
        let setsTasksId = new Set<string>(); //tap hop cac task da xu ly
        let sprintReport: any = {};
        const workingTimeState: IOffDaysWfhDaysState = yield select((state: any) => state.workingTime);
        let workingTime = workingTimeState.workingTime;
        let offDaysWfhDays = workingTimeState.offDaysWfhDays;
        const userExpectations: IUserExpectation[] = yield select((state: any) => state.userExpectationState.data);
        let mapWorkingData: any = {};
        let mapExpectations: any = {};
        for (let task of tasks) {
            // if (task.assignee) {
            // trường hợp task to nhiều người làm bên trong và task to đó không gán assignee thì chỗ này bị sai, nhưng các task con của nó vẫn phải được tính, nên cần chú ý cái này
            // can phai xu ly tat ca moi nguoi
            // handel sprint exp data
            if (task.assigneeId) {
                //task nào có assigneeId thì mới tính điểm
                getSprintReportData(
                    task.assigneeId,
                    sprintReport,
                    mapWorkingData,
                    workingTime,
                    offDaysWfhDays,
                    mapExpectations,
                    userExpectations,
                    currentSprint,
                    mapCoef
                );
                let taskReportItem = convertTaskToReport(task, currentSprint);
                if (!sprintReport[task.assigneeId]?.tasks)
                    //bug undefine field
                    sprintReport[task.assigneeId].tasks = [];
                sprintReport[task.assigneeId].tasks.push(taskReportItem);
            }

            // phần này là để kéo task sang sprint sau---------------------------------------------------
            if (!setsTasksId.has(task._id)) {
                // task cha chi duoc done khi toan bo task con da done
                // task cha có thể không được assign cho ai (task lớn nhiều người làm vào các task con bên trong)
                setsTasksId.add(task._id); // da duyet qua
                let queue = [task]; //'task' la root
                while (!!queue.length) {
                    let t = queue.shift();
                    // chỗ này không cần xét đến việc task đó có assign cho ai hay không
                    if (t) {
                        setsTasksId.add(t._id);
                        for (let st of tasks) {
                            //add subTask (n_th layer)
                            if (st.parentId === t?._id || st.parent?._id === t?._id) {
                                queue.push(st);
                            }
                        }
                        if (task?.statusId !== Config.COLUMN_STATUS.DONE.mongoId) {
                            // neu task root chua done tuc la ton tai task con chua done, khi do chuyen het sang next sprint
                            let histories = t?.histories ?? [];
                            histories.push(
                                new TaskHistory({
                                    field: "sprint",
                                    from: currentSprint._id,
                                    to: newSprint?._id,
                                    userID: currentUser._id,
                                    time: new Date(),
                                })
                            );
                            let _taskUpdate: TaskUpdate = {
                                _id: t._id,
                                sprint: newSprint?._id ?? "",
                                histories,
                                //neu task van o TO DO thi sang sprint sau van se duoc tinh vao diem
                                finishedDate:
                                    t.finishedDate == -1
                                        ? t.statusId === Config.COLUMN_STATUS.TO_DO.mongoId
                                            ? -1
                                            : currentSprint.endDate.getTime() - 1
                                        : t.finishedDate,
                                point: t.statusId === Config.COLUMN_STATUS.TO_DO.mongoId ? t.point : 0,
                            };
                            tasksUpdate.push(_taskUpdate);
                        } else {
                            // neu task cha done, tuc la toan bo task con da done, thi tinh vao task hoan thanh
                            // mapUserTaskFinished[userID].finishedTasks += 1;
                        }
                        // mapUserTaskFinished[userID].totalTasks += 1;
                    }
                }
            }
            // }
        }
        for (let user of users) {
            //user khong co task
            getSprintReportData(
                user._id,
                sprintReport,
                mapWorkingData,
                workingTime,
                offDaysWfhDays,
                mapExpectations,
                userExpectations,
                currentSprint,
                mapCoef
            );
        }
        yield call(updateTasksAPI, tasksUpdate); // update tasks status);
        yield call(updateSprintTasksAPI, sprintReport, currentSprint._id);
        yield delay(5000);
        yield put(reload());
    }
}

/**
 * Get new sprint report data
 * @param userId
 * @param sprintReport
 * @param mapWorkingData
 * @param workingTime
 * @param offDaysWfhDays
 * @param mapExpectations
 * @param userExpectations
 * @param currentSprint
 */
const getSprintReportData = (
    userId: string,
    sprintReport: any,
    mapWorkingData: any,
    workingTime: IWorkingTime[],
    offDaysWfhDays: IOffDaysWfhDays[],
    mapExpectations: any,
    userExpectations: IUserExpectation[],
    currentSprint: ISprint,
    mapCoef: any
) => {
    if (!sprintReport[userId]) {
        sprintReport[userId] = {
            tasks: [],
            disciplineData: null,
        };
    }
    if (!mapWorkingData[userId]) {
        mapWorkingData[userId] = {
            workingTime: workingTime.filter((day: any) => day.userId === userId),
            offDaysWfhDay: offDaysWfhDays.find((day: any) => day.userId === userId),
        };
    }

    if (!sprintReport[userId]?.coefficients) {
        //he so ca nhan can duoc luu theo sprint
        sprintReport[userId].coefficients = mapCoef[userId] ?? new UserCoefficient();
    }
    if (!mapExpectations[userId]) {
        mapExpectations[userId] =
            userExpectations.find((u) => u.userId === userId) ??
            new UserExpectation({
                userId: userId,
                lastUpdated: new Date(),
            });
    }
    if (!sprintReport[userId]?.disciplineData) {
        let _discipline = converDisciplineDataToReport(
            currentSprint,
            mapWorkingData[userId].offDaysWfhDay,
            mapWorkingData[userId].workingTime
        );
        sprintReport[userId].disciplineData = _discipline;
    }
    if (!sprintReport[userId]?.expectationData) {
        sprintReport[userId].expectationData = mapExpectations[userId];
    }
};
async function getSprintExpDataAPI(userId: string, sprintId: string) {
    let result = await axios.post(Url("sprint-report-v2/get-all-exp"), {
        userId,
        sprintId,
    });
    return result?.data;
}

export async function updateCheckpointTaskAPI({
    sprintId,
    taskId,
    checkPoint,
}: {
    sprintId: string;
    taskId: string;
    checkPoint: boolean;
}) {
    let result = await axios.post(Url("sprint-report-v2/update-checkpoint-task-by-sprint"), { sprintId, taskId, checkPoint });
}

export function* getSprintExpDataSaga(action: ISprintReportAction) {
    try {
        const userInfo: IUserInfo = yield select((state: any) => state.authState.user);
        let userId = action.userIds ? "" : roleForPoint(userInfo.role) ? userInfo._id : "";
        let result: [] = yield call(getSprintExpDataAPI, userId, action.sprint?._id ?? "");
        let expectations = result.map((r: any) => {
            return new UserExpectation({
                ...r.expectations,
                userId: r.user,
                sprintId: r.sprint,
            });
        });
        if (!action.sprint?.activeSprint) {
            yield put(getAllExpectationsSuccess(expectations));
        }
        yield put(getSprintExpDataSuccess(result));
    } catch (err) {
        yield put(getSprintExpDataFailed(err));
    }
}
export function* getRewardMinusExpSaga(action: ISprintReportAction) {
    let listUserIds = action.userIds;
    if (listUserIds?.length) {
        let data: any[] = yield call(getRewardMinusExpAPI, listUserIds);
        if (data) {
            yield put(getRewardMinusExpSuccess(data, listUserIds));
        }
    }
}
async function getRewardMinusExpAPI(listUserIds: string[]): Promise<any> {
    let result = await axios.post(Url("sprint-report-v2/get-reward-minus-exp"), { listUserId: listUserIds });
    return result?.data ?? null;
}

export function* sendMailToMemberTeamDEVSaga(action: ISprintReportAction) {
    const { sprint, userId } = action;
    const members: IUserInfo[] = yield select((state) => state.userInfoState.userInfos);
    const member = members.find((e) => e._id == userId);
    console.log("member ", member);

    if (member?._id && member.teams == "technical") {
        axios.post(Url("user/reset-points"), { sprintId: sprint?._id, userIds: [userId] });

        // axios.post(Url("sprint-report-v2/send-email-report-team-dev"), { sprintId: sprint?._id, userId });
        //callAPI send
    } else {
        window.alert("No permission");
    }
}

export function* watchSprint() {
    yield takeLatest(SprintTypes.GET_ALL_SPRINT, getAllSprintSaga);
    yield takeLatest(SprintReportTypes.UPDATE_REPORT, updateUpdateReport);
    yield takeLatest("END_SPRINT", updateEndSprintSaga);
    yield takeLatest(SprintReportTypes.GET_REWARD_MINUS_EXP_ACTION, getRewardMinusExpSaga);
    yield takeLatest(SprintReportTypes.GET_SPRINT_REPORT_V2_DATA, getSprintExpDataSaga);
    yield takeLatest(SprintReportTypes.SEND_MAIL_TO_MEMBER_TEAM_DEV, sendMailToMemberTeamDEVSaga);
}
