export default class Config {
    static DISCORD_CHANNELS = {
        CALENDAR:
            "https://discord.com/api/webhooks/895978524614201345/zzfsk3zB4K1pXRej8-8dXGi9DkrSgpCAu-OaBAHEwmJmuo_99IZzrcUePhASU_sYF2ar",
        NOTIFICATION:
            "https://discord.com/api/webhooks/896079894520401961/y5oAAMHU9DDkmPuSTAKLiQPDsCKMjcQRXHgwKiNbTqLz_bpU2OydB5HmR2cutaUYOiA5",
        BOT_URL: "https://discord-bot.abc-elearning.org",
        BOT_SEND_MESSAGE_URL: "https://discord-bot.abc-elearning.org/bot-send-message",
    };

    static HERO_AVATAR_URL = "https://storage.googleapis.com/micro-enigma-235001.appspot.com/images/superhero.png";

    static COLUMN_STATUS = {
        TO_DO: {
            id: 0,
            name: "to do",
            mongoId: "616eab3eccb9dac54a200202",
        },
        IN_PROGRESS: {
            id: 1,
            name: "in progress",
            mongoId: "616eab5eccb9dac54a200214",
        },
        QA_TESTING: {
            id: 2,
            name: "qa testing",
            mongoId: "616eab7bccb9dac54a20021d",
        },
        REVIEW_CODE: {
            id: 3,
            name: "review code",
            mongoId: "616eabb5ccb9dac54a20022b",
        },
        TO_DEPLOY: {
            id: 4,
            name: "to deploy",
            mongoId: "616eabdaccb9dac54a200237",
        },
        PROD_VALIDATION: {
            id: 5,
            name: "prod validation",
            mongoId: "616eabf9ccb9dac54a200240",
        },
        DONE: {
            id: 6,
            name: "done",
            mongoId: "616eac0fccb9dac54a200248",
        },
    };
    static _ID_ANH_LAM = "6157c5d6e40d4cf6e89ff714";
    static _ID_ANH_TRUONG = "6157c696e40d4cf6e89ff757";
    static PROJECT_ID_WORKSHEET = "61ebb0b21d081e3353909219";
    static SCORE_FOR_A_SPRINT = 200;
    static TARGET_STATUS = {
        TO_DO: {
            id: 0,
            name: "to do",
        },
        IN_PROGRESS: {
            id: 1,
            name: "in progress",
        },
        DONE: {
            id: 2,
            name: "done",
        },
    };
    // bucket google cloud
    static BUCKET_NAME = "accuplacer/";
    static SERVICE_KEY = "../../micro-enigma-235001-1a6df856a9e2.json";
    static PROJECT_ID = "micro-enigma-235001";
    static COLOR_IN_PROGRESS_GOAL = "rgb(77, 202, 241)";
    static COLOR_TODO_GOAL = "rgb(201, 206, 214)";
    static COLOR_DONE_GOAL = "rgb(80, 201, 143)";
    static ROLE = {
        STAFF: {
            id: 0,
            name: "staff",
        },
        ADMIN: {
            id: 1,
            name: "admin",
        },
    };

    static STATUS = {
        DELETED: {
            id: -1,
            name: "deleted",
        },
        DISABLE: {
            id: 0,
            name: "disable",
        },
        PUBLIC: {
            id: 1,
            name: "public",
        },
    };

    static TOKEN_NOT_LOGIN = 0;
    static TOKEN_LOGGED_IN = 1;

    static POSITIONS = {
        CF_HO_PM: {
            id: 0,
            name: "Co-Founder & Head of Operation & PM",
        },
        F_CEO: {
            id: 1,
            name: "Founder & CEO",
        },
        CF_PM_CMO: {
            id: 2,
            name: "Co-Founder & PM & CMO",
        },
        MARKETER_PM: {
            id: 3,
            name: "Marketer & PM",
        },
        LEAD_DEV: {
            id: 4,
            name: "Lead Dev",
        },
        UI_DESIGNER: {
            id: 5,
            name: "UI Designer",
        },
        DEVELOPER: {
            id: 6,
            name: "Developer",
        },
        TESTER: {
            id: 7,
            name: "Tester",
        },
        FRESHER_DEVELOPER: {
            id: 8,
            name: "Fresher Developer",
        },
        CONTENT_EDITOR: {
            id: 9,
            name: "Content Editor",
        },
        DEVELOPER_INTERN: {
            id: 10,
            name: "Developer Intern",
        },
        HR: {
            id: 11,
            name: "Human Resources",
        },
        MARKETER: {
            id: 12,
            name: "Marketer",
        },
        TESTER_INTERN: {
            id: 13,
            name: "Tester Intern",
        },
        OTHER: {
            id: 14,
            name: "Other",
        },
    };

    static TEAMS = {
        DEV: {
            id: 0,
            name: "dev",
        },
        CONTENT: {
            id: 1,
            name: "content",
        },
        MARKETING: {
            id: 2,
            name: "marketing",
        },
        DESIGN: {
            id: 3,
            name: "design",
        },
        TESTER: {
            id: 4,
            name: "tester",
        },
        HR: {
            id: 5,
            name: "hr",
        },
        MANAGER: {
            id: 6,
            name: "manager",
        },
        TECHNICAL: {
            id: 7,
            name: "technical",
        },
        OTHER: {
            id: 8,
            name: "other",
        },
    };
    static TEAMS_GOALS = [
        { value: "App", label: "App" },
        { value: "Web", label: "Web" },
        { value: "Marketing", label: "Marketing" },
        { value: "Design", label: "Design" },
        { value: "Content", label: "Content" },
        { value: "SEO", label: "SEO" },
    ]; //
    static ONE_DAY_MILLISECONDS = 1 * 24 * 60 * 60 * 1000;
    static ONE_HOURS_MILLISECONDS = 60 * 60 * 1000;
    static ONE_WEEK_MILLISECONDS = 7 * this.ONE_DAY_MILLISECONDS;
    static TWO_WEEK_MILLISECONDS = 2 * this.ONE_WEEK_MILLISECONDS;
    static THREE_WEEK_MILLISECONDS = 3 * this.ONE_WEEK_MILLISECONDS;
    static ONE_MONTH_MILLISECONDS = 4 * this.ONE_WEEK_MILLISECONDS;
    static TWO_MONTH_MILLISECONDS = 2 * this.ONE_MONTH_MILLISECONDS;
    static THREE_MONTH_MILLISECONDS = 3 * this.ONE_MONTH_MILLISECONDS;
    static TASK_SUBMIT_ID = 9;
    static PAA_BLOG_TYPE = 1;
    static YOUTUBE_BLOG_TYPE = 2;
    static BLOG_NOT_CRAWL = 1;
    static BLOG_CRAWL_NEED_UPLOAD = 3;
    static BLOG_CRAWL_CHECKED = 2;
    static BLOG_CRAWL_SUCCESS = 4;
    static BLOG_CRAWL_ERROR = 5;

    static NOT_CHECKIN_ALL_DAY = "Không checkin, checkout cả ngày";
    static NOT_CHECKIN_MORNING = "Không checkin buổi sáng";
    static NOT_CHECKOUT_AFTERNOON = "Không checkout buổi chiều";

    static LATER_CHECKIN_MORNING = "Checkin buổi sáng muộn";
    static EARLY_CHECKOUT_AFTERNOON = "Checkout buổi chiều sớm";

    static MORNING_VNESE = "Buổi sáng";
    static AFTERNOON_VNESE = "Buổi chiều";
    static ALL_DAY_VNESE = "Cả ngày";

    static MORNING = "morning";
    static AFTERNOON = "afternoon";
    static ALL_DAY = "allday";

    static LATE = {
        timeInMorning: 8 * 60 + 30,
        timeInAfternoon: 13 * 60 + 30,
        lte10: { des: 5, gt: 0, lte: 10 },
        gt10lte20: { des: 10, gt: 10, lte: 20 },
        gt20: { des: 15, gt: 20 },
        notcheck: 15,
        unauthorized: 30,
    };

    static LATE88 = {
        timeInMorning: 8 * 60 + 30,
        timeInAfternoon: 13 * 60 + 30,
        gt60: { des: 60, gt: 60 },
        notCheckin: 60,
        notCheckout: 15,
        unauthorized: 30,
    };

    static CHECKOUT_MORNING = "10:00";
    static CHECKOUT_AFTERNOON = "16:00";

    static NONE = "none";
    static ONE_DAY = "1 day";
    static ONE_WEEK = "1 week";
    static TWO_WEEKS = "2 weeks";
    static ONE_MONTH = "1 month";
    static THREE_MONTHS = "3 months";
    static SIX_MONTHS = "6 months";
    static ONE_YEAR = "1 year";

    static PERSONS = "persons";
    static TEAMS_LABEL = "teams";
    static ALL_TEAMS_LABEL = "all teams";
    static CHANNELS = "channels";
    static DISCORD_CHANNELS_LIST = [
        {
            name: "dev",
            discordID: "869049754833788969",
        },
        {
            name: "sinh-hoat-chung",
            discordID: "869048327042388038",
        },
    ];

    static WORKSHEET = "61ebb0b21d081e3353909219";
    static DEFAULT_POINT = 80;
    static HTTP_RESULT_OK = "OK";

    static PRIORITY_POOL = {
        HIGHEST: "Highest",
        HIGH: "High",
        MEDIUM: "Medium",
        LOW: "Low",
        LOWEST: "Lowest",
    };
    // static COEF = { DEFAULT: 1, LOW: 0.5, HIGHT: 1.5 };
    static COEF = {
        N1: 0.1,
        N2: 0.2,
        N3: 0.3,
        N4: 0.4,
        LOW: 0.5,
        N6: 0.6,
        N7: 0.7,
        N8: 0.8,
        N9: 0.9,
        DEFAULT: 1,
        N11: 1.1,
        N12: 1.2,
        N13: 1.3,
        N14: 1.4,
        HIGH: 1.5,
    };

    static PRIORITY_JOB = {
        AAA: "aaa",
        BBB: "bbb",
        CCC: "ccc",
        DDD: "ddd",
    };
    static RANK_JOB = {
        "Junior developer": "Junior developer",
        "Senior developer": "Senior developer",
        "Lead developer hoặc Architecht": "Lead developer hoặc Architecht",
        "Mid-level manager": "Mid-level manager",
        "Senior leader": "Senior leader",
    };
    static FORM_JOB = {
        "Part Time": "Part Time",
        "Full Time": "Full Time",
        Remote: "Remote",
    };
    static LANGUAGE_JOB = {
        Javascript: "Javascript",
        Flutter: "Flutter",
        "Native IOS": "Native IOS",
        "Native Android": "Native Android",
        Python: "Python",
        ReactJS: "ReactJS",
        NodeJS: "NodeJS",
        "Mongo(CRUD)": "Mongo(CRUD)",
        ElasticSearch: "ElasticSearch",
        "Gcloud Datastore Gcloud Bigquery": "Gcloud Datastore Gcloud Bigquery",
        MySQL: "MySQL",
        Nginx: "Nginx",
        "HTML/CSS": "HTML/CSS",
        Git: "Git",
    };
    static LOCATION_JOB = {
        "Hà Nội": "Hà Nội",
    };

    /** project cũ => todo: merge project mới */
    static USER_ROLE_INTERN = -1;
    static USER_ROLE_STAFF = 0;
    static USER_ROLE_ADMIN = 1;
    static USER_ROLE_PARTNER = 2;
    static USER_ROLE_CREATE_BLOG = 3;
    static USER_ROLE_LEADER = 4;

    static STATUS_TASK_TO_DO = "TO DO";
    static STATUS_TASK_IN_PROGRESS = "IN PROGRESS";
    static STATUS_TASK_QA_TESTING = "QA TESTING";
    static STATUS_TASK_REVIEW_CODE = "REVIEW CODE";
    static STATUS_TASK_TO_DEPLOY = "TO DEPLOY";
    static STATUS_TASK_PROD_VALIDATION = "PROD VALIDATION";
    static STATUS_TASK_DONE = "DONE";

    static STATUS_DELETED = -1;
    static STATUS_DISABLE = 0;
    static STATUS_PUBLIC = 1;
    /** project cũ => todo: merge project mới */

    static SOCKET_PORT = 4039;
    static TYPE = {
        holiday: "holiday",
        complementDay: "complementDay",
        offday: "dayoff",
        wfhday: "wfh",
    };
    static ADD_SHARING_SCORE = 1;
    static SUBTRACT_SHARING_SCORE = -1;
    static EXISTING_SHARING = 1; // lich sharing dang duoc hien thi
    static DELETED_SHARING = 0; // lich sharing da bi xoa
    static TIMEZONE = "Asia/Ho_Chi_Minh";
    static TIME_TO_WORK = "07:00:00";
    static OFF_TYPE_AUTHORIZED = "authorized";
    static OFF_TYPE_URGENT_1 = "urgent_1";
    static OFF_TYPE_URGENT_2 = "urgent_2";
    static OFF_TYPE_DEFAULT = "default";
    static OFF_TYPE_UNAUTHORIZED = "unAuthorized";
    static OFF_TYPE = {
        [this.OFF_TYPE_DEFAULT]: 30,
        [this.OFF_TYPE_AUTHORIZED]: 0,
        [this.OFF_TYPE_URGENT_1]: 15,
        [this.OFF_TYPE_URGENT_2]: 30,
        [this.OFF_TYPE_UNAUTHORIZED]: 90,
    };
    static DEFAULT_URGENT_OFFDAY_PER_SPRINT = 1;
    static DEFAULT_URGENT_OFFDAY_PER_MONTH = 1;
    static URGENT_TIME = (24 - 17 + 7) * this.ONE_HOURS_MILLISECONDS; // sau 17h hôm trước đến 7h hôm sau
    static STANDAR_EXP = 12 * (80 * 10 + 100);
    static COEFFICIENT = {
        WORK: 10,
        DISCIPLINE: 1,
        CREATIVE: 0,
        LEADERSHIPS: 0,
        ACTIVE: 0,
        QUALITY: 0,
    };
    static PROBLEM_TYPE_OPTION = [
        {
            value: "dev",
            label: "DEV",
        },
        {
            value: "content",
            label: "Content",
        },
        {
            value: "design",
            label: "Design",
        },
        {
            value: "other",
            label: "Other",
        },
    ];
    static PERMISSION_EDITOR_CAMPAIGN = "Editor Campaign";
    static PERMISSIONS_GROUP = [{ value: "Editor Campaign", label: "Editor Campaign" }];
    static APP_NAME = [
        { value: "5744053972893696", label: "CompTIA Security+ " },
        { value: "5551925043920896", label: "CompTIA A+ " },
        { value: "5296397775536128", label: "GED" },
        { value: "4640628697726976", label: "CompTIA Network+ " },
        { value: "5296397775536128", label: "GED Test " },
        { value: "5552846747467776", label: "DMV" },
        { value: "4671547308507136", label: "CDL" },
        { value: "5685410002894848", label: "MCAT" },
        { value: "5962925959282688", label: "AWS" },
        { value: "5397248982646784", label: "FSC" },
        { value: "4516085158117376", label: "CCNA" },
        { value: "4878338973761536", label: "ASVAB" },
        { value: "6667648976814080", label: "NATE" },
        { value: "5643531427250176", label: "ASE" },
        { value: "6138734564081664", label: "Real Estate" },
        { value: "6341062520995840", label: "Ontario G1" },
        { value: "5186025303310336", label: "TEAS" },
        { value: "6119576033034240", label: "ACCUPLACER" },
        { value: "-1", label: "Part107" },
        { value: "4811628111462400", label: "HVAC" },
        { value: "5243570678136832", label: "EMT-B" },
        { value: "4574196346650624", label: "CISSP" },
        { value: "4794568409088000", label: "CEH" },
        { value: "5258846803066880", label: "TABE" },
        { value: "5017963709071360", label: "NCLEX-RN" },
        { value: "5719960095555584", label: "MBLEX" },
        { value: "5657969412800512", label: "HESI A2" },
        { value: "5753091221618688", label: "PTCE" },
        { value: "4794568409088000", label: "CEH v11" },
        { value: "6328870527565824", label: "Phlebotomy" },
        { value: "6098076194308096", label: "GRE" },
        { value: "-1", label: "CDL Flutter" },
        { value: "4913995070832640", label: "PHR" },
        { value: "4691917260455936", label: "NCLEX-PN " },
        { value: "5615326898159616", label: "CFA" },
        { value: "-1", label: "CDL React Native" },
        { value: "6074233270566912", label: "EPA 608" },
        { value: "5746867797229568", label: "Servsafe" },
        { value: "-1", label: "Drivingtheory" },
        { value: "4836669045866496", label: "USMLE" },
        { value: "6504182246801408", label: "HISET" },
    ];

    static PROBLEM_STATE_OPTION = [
        {
            value: "done",
            label: "Done",
        },
        {
            value: "in_progress",
            label: "In progress",
        },
    ];
}
