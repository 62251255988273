import {
    convertDate,
    getProjectFromJson,
    getSprintFromJson,
    getStatusFromJson,
    getTaskFromJson,
    getUserFromJson,
} from "../utils";
import { IBasic } from "./basic";
import { IProject } from "./project";
import { ISprint } from "./sprint";
import { IStatus } from "./status";
import { IUserInfo } from "./userInfo";

export interface ITaskComment extends IBasic {
    userID: string;
    createdDate: Date;
    lastUpdate: Date;
    content: string;
    taskId: string;
}

export interface ITaskHistory extends IBasic {
    userID: string;
    from: string;
    to: string;
    field: string;
    time: Date;
    sendedMessage: boolean;
}

export class TaskComment implements ITaskComment {
    _id: any;
    userID: string;
    createdDate: Date;
    content: string;
    taskId: string;
    lastUpdate: Date;

    constructor(props: any = {}) {
        this._id = props._id;
        this.userID = props.userID ?? "";
        this.content = props.content ?? "";
        this.taskId = props.taskId ?? "";
        this.createdDate = convertDate(props.createdDate) ?? new Date();
        this.lastUpdate = convertDate(props.lastUpdate) ?? new Date();
    }
}

export class TaskHistory implements ITaskHistory {
    _id: any;
    userID: string;
    from: string;
    to: string;
    field: string;
    time: Date;
    sendedMessage: boolean;
    constructor(props: any = {}) {
        this._id = props.id ?? props._id ?? null;
        this.field = props.field ?? "";
        this.to = props.to ?? "";
        this.from = props.from ?? "";
        this.userID = props.userID ?? "";
        this.time = convertDate(props.time) ?? new Date();
        this.sendedMessage = !!props.sendedMessage;
    }
}

export interface ITask extends IBasic {
    assignee?: IUserInfo;
    assigneeId: string;
    // branchGitHub: string;
    checkPoint: boolean;
    coef: number;
    comments: ITaskComment[];
    confirmedPoints: number | undefined;
    consumedPoints: number | undefined;
    createdTime: Date;
    createdUserId?: string;
    deadline: Date | null;
    description: string;
    failedReason: string | undefined;
    finishedDate: number;
    // followerIds: string[];
    // followers?: IUserInfo[];
    histories: ITaskHistory[];
    inform: string[];
    leadDev?: IUserInfo;
    leadDevId: string;
    owner?: IProject;
    ownerId?: string;
    parent?: ITask;
    parentId: string;
    point: number | undefined;
    priority: string;
    progressSubmitAppId: number;
    project?: IProject;
    projectId: string;
    reporter?: IUserInfo;
    reporterId: string;
    shortId: string;
    sprint?: ISprint;
    sprintId: string;
    status?: IStatus;
    statusId: string;
    statusView: number;
    submitApp: boolean;
    testerIds: string[];
    testers?: IUserInfo[];
    testLink: string;
    title: string;
    type: "Task" | "Bug" | "Sub Task";
}

export class Task implements ITask {
    _id: any;
    assignee?: IUserInfo;
    assigneeId: string;
    // branchGitHub: string;
    checkPoint: boolean;
    coef: number;
    comments: ITaskComment[];
    confirmedPoints: number | undefined;
    consumedPoints: number | undefined;
    createdTime: Date;
    createdUserId?: string;
    deadline: Date | null;
    description: string;
    failedReason: string | undefined;
    finishedDate: number;
    // followerIds: string[];
    // followers?: IUserInfo[];
    histories: ITaskHistory[];
    inform: string[];
    leadDev?: IUserInfo;
    leadDevId: string;
    owner?: IProject;
    parent?: ITask;
    parentId: string;
    point: number | undefined;
    priority: string;
    progressSubmitAppId: number;
    project?: IProject;
    projectId: string;
    reporter?: IUserInfo;
    reporterId: string;
    shortId: string;
    sprint?: ISprint;
    sprintId: string;
    status?: IStatus;
    statusId: string;
    statusView: number;
    submitApp: boolean;
    testerIds: string[];
    testers?: IUserInfo[];
    testLink: string;
    title: string;
    type: "Task" | "Bug" | "Sub Task";

    constructor(object: any = {}) {
        this._id = object._id ?? object.id;
        this.title = object.title ?? "";
        this.description = object.description ?? "";
        this.type = object.type ?? "";
        this.point = object.point;
        this.confirmedPoints = object.confirmedPoints;
        this.consumedPoints = object.consumedPoints;
        this.deadline = convertDate(object.deadline);
        this.priority = object.priority ?? "";
        this.failedReason = object.failedReason ?? "";
        this.createdTime = object.createdTime ?? new Date(0);
        let _assigneeId = "";
        this.coef = object.coef ?? 1;
        this.checkPoint = !!object.checkPoint;
        if (object.assignee && typeof object.assignee === "string") {
            _assigneeId = object.assignee;
        } else {
            this.assignee = getUserFromJson(object.assignee);
            _assigneeId = this.assignee?._id ?? "";
        }
        if (typeof object.assigneeId === "string") {
            _assigneeId = object.assigneeId;
        } else if (typeof object.assigneeID === "string") {
            _assigneeId = object.assigneeID;
        }
        this.assigneeId = _assigneeId;
        let _leadDevId = "";
        if (object.leadDev && typeof object.leadDev === "string") {
            _leadDevId = object.leadDev;
        } else {
            this.leadDev = getUserFromJson(object.leadDev);
            _leadDevId = this.leadDev?._id ?? "";
        }
        if (typeof object.leadDevId === "string") {
            _leadDevId = object.leadDevId;
        } else if (typeof object.leadDevID === "string") {
            _leadDevId = object.leadDevID;
        }
        this.leadDevId = _leadDevId;

        let _reporterId = "";
        if (object.reporter && typeof object.reporter === "string") {
            _reporterId = object.reporter;
        } else {
            this.reporter = getUserFromJson(object.reporter);
            _reporterId = this.reporter?._id ?? "";
        }
        if (typeof object.reporterId === "string") {
            _reporterId = object.reporterId;
        } else if (typeof object.reporterID === "string") {
            _reporterId = object.reporterID;
        }
        this.reporterId = _reporterId;
        this.testerIds = [];
        this.testers = [];
        if (object.tester && typeof object.tester === "string") {
            this.testerIds.push(object.tester);
        } else {
            let _tester = getUserFromJson(object.tester);
            if (_tester) {
                this.testers.push(_tester);
                this.testerIds.push(_tester._id);
            }
        }
        if (typeof object.testerId === "string") {
            this.testerIds.push(object.testerId);
        } else if (typeof object.testerID === "string") {
            this.testerIds.push(object.testerID);
        }
        if (typeof object.testers === "object") {
            for (let _tester of object.testers) {
                if (typeof _tester === "string") {
                    if (this.testerIds.indexOf(_tester) == -1) {
                        this.testerIds.push(_tester);
                    }
                } else {
                    let _test = getUserFromJson(_tester);
                    if (_test) {
                        if (this.testers.findIndex((t) => t._id == _test?._id) == -1) {
                            this.testers.push(_tester);
                        }
                        if (this.testerIds.indexOf(_test._id) == -1) {
                            this.testerIds.push(_test._id);
                        }
                    }
                }
            }
        } else if (typeof object.testerIds === "object") {
            this.testerIds = object.testerIds;
        }
        this.inform = object.inform ?? [];
        if (Array.isArray(this.inform)) {
            this.inform = this.inform.filter((i) => !!i);
        }
        let _projectId = "";
        if (object.project && typeof object.project === "string") {
            _projectId = object.project;
        } else {
            this.project = getProjectFromJson(object.project);
            _projectId = this.project?._id ?? "";
        }
        if (typeof object.projectId === "string") {
            _projectId = object.projectId;
        } else if (typeof object.projectID === "string") {
            _projectId = object.projectID;
        }
        this.projectId = _projectId;
        let _sprintId = "";
        if (object.sprint && typeof object.sprint === "string") {
            _sprintId = object.sprint;
        } else {
            this.sprint = getSprintFromJson(object.sprint);
            _sprintId = this.sprint?._id ?? "";
        }
        if (typeof object.sprintId === "string") {
            _sprintId = object.sprintId;
        } else if (typeof object.sprintID === "string") {
            _sprintId = object.sprintID;
        }
        this.sprintId = _sprintId;
        let _statusId = "";
        if (object.status && typeof object.status === "string") {
            _statusId = object.status;
        } else {
            this.status = getStatusFromJson(object.status);
            _statusId = this.status?._id ?? "";
        }
        if (typeof object.statusId === "string") {
            _statusId = object.statusId;
        } else if (typeof object.statusID === "string") {
            _statusId = object.statusID;
        }
        this.statusId = _statusId;
        let _parentId = "";
        if (object.parent && typeof object.parent === "string") {
            _parentId = object.parent;
        } else if (object.parent) {
            this.parent = getTaskFromJson(object.parent);
            _parentId = this.parent?._id ?? "";
        }
        if (typeof object.parentId === "string") {
            _parentId = object.parentId;
        } else if (typeof object.parentID === "string") {
            _parentId = object.parentID;
        }
        this.parentId = _parentId;
        this.shortId = object.shortId ?? "";
        this.histories = [];
        if (typeof object.histories === "object") {
            for (let history of object.histories) {
                this.histories.push(new TaskHistory(history));
            }
        }
        this.submitApp = !!object.submitApp;
        this.progressSubmitAppId = object.progressSubmitAppId ?? "";
        this.finishedDate = convertDate(object.finishedDate)?.getTime() ?? -1;
        this.statusView = object.statusView ?? -1;
        // let _followerIds: string[] = [];
        // this.followers = [];
        // if (typeof object.followers === "object") {
        //     for (let follower of object.followers) {
        //         if (typeof follower === "string") {
        //             _followerIds.push(follower);
        //         } else {
        //             let user = getUserFromJson(follower);
        //             user && this.followers.push(user);
        //         }
        //     }
        // } else if (typeof object.followerIds === "object") {
        //     _followerIds = object.followerIds;
        // }
        // this.followerIds = _followerIds;
        this.testLink = object.testLink ?? "";
        this.comments = object.comments ?? [];
        this.owner = getProjectFromJson(object.owner);
        // this.branchGitHub = object.branchGitHub ?? "";
        this.createdUserId = object.createdUserId ?? "";
    }

    toJson() {
        return {
            ...this,
            testers: this.testers?.map((t) => t._id) ?? [],
            // tester: null,
        };
    }

    static isObject(item: any): boolean {
        if (item) {
            if (item.constructor.name == this.name) {
                return true;
            }
            const list = [
                "_id",
                "title",
                "type",
                "assigneeId",
                "reporterId",
                "leadDevId",
                "projectId",
                "sprintId",
                "statusId",
                "parentId",
                "inform",
                "priority",
            ];
            let count = 0;
            for (let attr of Object.getOwnPropertyNames(item)) {
                if (list.includes(attr)) {
                    count++;
                }
            }
            return count == list.length;
        }
        return false;
    }
}
