import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import moment, { Moment } from "moment";
import { FC, useEffect, useState } from "react";
import Constants from "../../constant";
import Config from "../../shared/models/config";
import { IUserInfo, UserInfo } from "../../shared/models/userInfo";
import { generateString, getAdmin, getLeaderGroup, getSetOfAllLeader } from "../../util";
import UploadPanel from "../upload";
import { IUserGroup, UserGroup } from "../../shared/models/userGroup";

const EditUserDialog: FC<{
    users: IUserInfo[];
    usersGroups: IUserGroup[];
    userInfo: IUserInfo;
    handleClose: () => void;
    onUpdate: any;
}> = ({ users, usersGroups, userInfo, handleClose, onUpdate }) => {
    const listAdmin = getAdmin(users);
    const listLeader = getLeaderGroup(usersGroups, userInfo, users);
    const allLeader = getSetOfAllLeader(listLeader, listAdmin);
    const title = userInfo._id ? `Sửa thông tin ${userInfo.firstName + " " + userInfo.lastName}` : "Thêm người dùng mới";
    const [avatar, setAvatar] = useState(userInfo.avatar ?? "");
    const [firstName, setFirstName] = useState(userInfo.firstName ?? "");
    const [lastName, setLastName] = useState(userInfo.lastName ?? "");
    const [gender, setGender] = useState(userInfo.gender ?? "");
    const [email, setEmail] = useState(userInfo.email ?? "");
    const [discordID, setDiscordID] = useState(userInfo.discordID ?? "");
    const [teams, setTeams] = useState(userInfo.teams ?? "");
    const [position, setPosition] = useState(userInfo.position ?? "");
    const [role, setRole] = useState(userInfo.role ?? 0);
    const [code, setCode] = useState(userInfo.employeeCode ?? "");
    const [startDate, setStartDate] = useState<Moment>(
        !userInfo._id || !userInfo.startDate ? moment() : moment(userInfo.startDate)
    );
    const [warning, setWarning] = useState("");
    const [password, setPassword] = useState(userInfo.password ?? "");
    const [directManagerID, setDirectManagerID] = useState(userInfo.directManagerID ?? "");
    const [birth, setBirth] = useState<Moment>(userInfo.birth && userInfo.birth > -1 ? moment(userInfo.birth) : moment());
    const [emailActive, setEmailActive] = useState(!!userInfo.emailActive);
    const [isChangePassword, setIsChangePassword] = useState(!!userInfo.isChangePassword);
    const [address, setAddress] = useState(userInfo.address ?? "");
    const [status, setStatus] = useState(userInfo.status);

    const [leadList, setLeadList] = useState([]);

    useEffect(() => {
        setAvatar(userInfo.avatar ?? "");
        setFirstName(userInfo.firstName ?? "");
        setLastName(userInfo.lastName ?? "");
        setGender(userInfo.gender ?? "");
        setEmail(userInfo.email ?? "");
        setDiscordID(userInfo.discordID ?? "");
        setTeams(userInfo.teams ?? "");
        setPosition(userInfo.position ?? "");
        setRole(userInfo.role ?? 0);
        setStartDate(!userInfo._id || !userInfo.startDate ? moment() : moment(userInfo.startDate));
        setPassword(userInfo.password ?? generateString(12));
        setDirectManagerID(userInfo?.directManagerID ?? "");
        setAddress(userInfo.address ?? "");
        setBirth(userInfo.birth && userInfo.birth > -1 ? moment(userInfo.birth) : moment());
        setEmailActive(!!userInfo.emailActive);
        setIsChangePassword(!!userInfo.isChangePassword);
        setStatus(userInfo.status);
    }, [userInfo]);

    const _onUpdate = async () => {
        let existUser = users.find((u) => u.employeeCode === code && u._id !== userInfo._id && u.status);
        if (existUser) {
            setWarning(existUser.lastName + " " + existUser.firstName);
            return;
        }
        let newUser = new UserInfo({
            ...userInfo,
            address: address?.trim() ?? "",
            avatar: avatar?.trim() ?? "",
            badge: 0,
            birth: birth.unix() * 1000,
            credit: 0,
            directManagerID: directManagerID?.trim() ?? "",
            discordID: discordID?.trim() ?? "",
            email: email.trim().includes("@abc-elearning.org") ? email.trim() : email.trim() + "@abc-elearning.org",
            emailActive: emailActive,
            employeeCode: code,
            firstName: firstName?.trim() ?? "",
            gender: gender?.trim() ?? "",
            isChangePassword: isChangePassword,
            lastName: lastName?.trim() ?? "",
            onBoardingPass: false,
            password: password?.trim() ?? "",
            position: position?.trim() ?? "",
            role: role ?? 0,
            startDate: startDate,
            status: status,
            teamId: 0,
            teams: teams?.trim() ?? "",
            totalScore: 0,
        });
        if (isValidate(newUser)) {
            onUpdate({
                ...userInfo,
                ...newUser,
            });
            handleClose();
            alert("Lưu thông tin thành công!");
            const dataUserCopied = "email: " + newUser.email + "\n" + "password: " + newUser.password;
            navigator.clipboard.writeText(dataUserCopied);
        }
    };
    return (
        <Dialog
            open={!!userInfo}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title2"
            aria-describedby="alert-dialog-description2"
        >
            <DialogTitle id="alert-dialog-title2">{title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item sm={12} md={6}>
                        <TextField
                            fullWidth={true}
                            type="text"
                            id="email"
                            label="Email*"
                            variant="standard"
                            value={email.replace(/@abc-elearning.org/, "")}
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">@abc-elearning.org</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            fullWidth={true}
                            type="text"
                            id="password"
                            label="Mật khẩu*"
                            variant="standard"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            fullWidth={true}
                            type="text"
                            id="firstName"
                            label="Tên*"
                            variant="standard"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            fullWidth={true}
                            type="text"
                            id="lastName"
                            label="Họ"
                            variant="standard"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Giới tính
                            </InputLabel>
                            <NativeSelect
                                defaultValue={gender}
                                inputProps={{
                                    name: "gender",
                                    id: "gender",
                                }}
                                onChange={(event) => setGender(event.target.value)}
                            >
                                <option></option>
                                <option value={"Nam"}>Nam</option>
                                <option value={"Nữ"}>Nữ</option>
                                <option value={"Khác"}>Khác</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            value={birth.format("yyyy-MM-DD")}
                            //chỉ admin có thể sửa được được người quản lý cho các thành viên
                            fullWidth={true}
                            type="date"
                            id="birth"
                            label="Ngày sinh"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                setBirth(moment(e.target.value));
                            }}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            fullWidth={true}
                            type="text"
                            id="discordID"
                            label="Discord ID"
                            variant="standard"
                            value={discordID}
                            onChange={(e) => setDiscordID(e.target.value)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Vị trí
                            </InputLabel>
                            <NativeSelect
                                defaultValue={position}
                                inputProps={{
                                    name: "team",
                                    id: "team",
                                }}
                                onChange={(event) => setPosition(event.target.value)}
                            >
                                {Constants.POSITION.map((position) => (
                                    <option value={position} key={position}>
                                        {position}
                                    </option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Đội ngũ
                            </InputLabel>
                            <NativeSelect
                                defaultValue={teams}
                                inputProps={{
                                    name: "team",
                                    id: "team",
                                }}
                                onChange={(event) => setTeams(event.target.value)}
                            >
                                <option></option>
                                <option value={"marketing"}>Marketing</option>
                                <option value={"technical"}>Technical</option>
                                <option value={"manager"}>Manager</option>
                                <option value={"content"}>Content</option>
                                <option value={"ui"}>UI</option>
                                <option value={"hr"}>HR</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Chức vụ
                            </InputLabel>
                            <NativeSelect
                                defaultValue={role}
                                inputProps={{
                                    name: "role",
                                    id: "role",
                                }}
                                onChange={(event) => setRole(parseInt(event.target.value))}
                            >
                                <option value={Config.USER_ROLE_STAFF}>Staff</option>
                                <option value={Config.USER_ROLE_ADMIN}>Admin</option>
                                <option value={Config.USER_ROLE_PARTNER}>Partner</option>
                                <option value={Config.USER_ROLE_CREATE_BLOG}>Create Blog</option>
                                <option value={Config.USER_ROLE_LEADER}>Leader</option>
                                <option value={Config.USER_ROLE_INTERN}>Intern</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>

                    <Grid item sm={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Direct Manager
                            </InputLabel>
                            <NativeSelect
                                defaultValue={directManagerID}
                                inputProps={{
                                    name: "name",
                                    id: "name",
                                }}
                                onChange={(event) => setDirectManagerID(event.target.value)}
                            >
                                <option></option>
                                {allLeader.map((user: IUserInfo, index: number) => (
                                    <option key={index} value={user._id}>
                                        {user.lastName} {user.firstName}
                                    </option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Grid>

                    {!userInfo.startDate && (
                        <Grid item sm={12} md={6}>
                            <TextField
                                value={startDate.format("yyyy-MM-DD")}
                                fullWidth={true}
                                type="date"
                                id="startDate"
                                label="Start date"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setStartDate(moment(e.target.value));
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item sm={12} md={6}>
                        <TextField
                            fullWidth={true}
                            type="text"
                            id="address"
                            label="Địa chỉ"
                            variant="standard"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Vô hiệu hoá
                            </InputLabel>
                            <NativeSelect
                                defaultValue={status}
                                inputProps={{
                                    name: "disable",
                                    id: "disable",
                                }}
                                onChange={(event) => {
                                    setStatus(parseInt(event.target.value));
                                }}
                            >
                                <option value={Config.STATUS_PUBLIC}>No</option>
                                <option value={Config.STATUS_DISABLE}>Yes</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            fullWidth={true}
                            type="text"
                            id="code"
                            label="Mã nhân viên"
                            variant="standard"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                        {warning && (
                            <div>
                                <i>{`*Mã nhân viên đã tồn tại, ${warning}`}</i>
                            </div>
                        )}
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <div>
                            <img width={"100px"} height={"100px"} src={avatar} alt="" />
                        </div>
                        <UploadPanel
                            image={avatar}
                            uploadSuccess={(image: string) => {
                                setAvatar(image);
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={_onUpdate} autoFocus>
                    {userInfo._id ? "Lưu" : "Tạo hồ sơ"}
                </Button>
                <Button onClick={handleClose}>Hủy</Button>
            </DialogActions>
        </Dialog>
    );
};

const isValidate = (newUser: IUserInfo) => {
    let email = newUser.email?.toLowerCase()?.replaceAll("@abc-elearning.org", "")?.trim() ?? "";
    if (!email) {
        alert("Chưa nhập email!");
        return false;
    }
    if (!newUser.firstName) {
        alert("Chưa nhập tên!");
        return false;
    }
    if (!newUser.directManagerID) {
        alert("Chưa chọn người quản lý!");
        return false;
    }
    if (!newUser.password) {
        alert("Chưa nhập mật khẩu!");
        return false;
    }
    if (!newUser.birth || newUser.birth <= 0) {
        alert("Chưa nhập ngày sinh!");
        return false;
    }
    return true;
};
export default EditUserDialog;
