import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import Constants from "../../../constant";
import { IWebState } from "../../../redux/reducers/web.reducer";
import { Campaign } from "../../../shared/models/campaign";
import { ISprint } from "../../../shared/models/sprint";
import { IUserInfo } from "../../../shared/models/userInfo";
import { isAdmin, workingDaysBetweenDates } from "../../../util";
import CreateCampaignModal from "../campaign/create-campaign-modal";
import CreateSprintModal from "../sprint/create-sprint-modal";
import CreateTaskModal from "../task-preview/create-task-modal";
import UserProgressUpdateModal from "../update-user-progress";
import "./style.scss";
import { updateUserScoreAPI } from "../../../redux/api/report.api";
import { IStatus } from "../../../shared/models/status";
import { IProject } from "../../../shared/models/project";

const DashboardSidebar: FC<{
    backButton: boolean;
    currentSprintID: string;
}> = ({ backButton, currentSprintID }) => {
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const projects = useSelector((state: any) => state.projectState.projects ?? []);
    const pageName = useSelector((state: any) => state.page);
    const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
    const [showCreateCampaignModal, setShowCreateCampaignModal] = useState(false);
    const history = useHistory();
    const params: any = useParams();
    const { id } = params;

    let sprintID: string = currentSprintID ?? id;
    if (!sprintID) {
        sprintID = sprints.find((task: any) => task.activeSprint)?._id ?? "";
    }
    const currentSprint = sprints.find((sprint: any) => sprint._id === sprintID);
    const showAllSprint = (history.location.search?.indexOf("showAllSprint=true") ?? -1) > -1;
    const listSprints = showAllSprint ? sprints : sprints.filter((a) => a.status == Constants.STATUS_PUBLIC); // lọc những sprint là backlog và active
    listSprints.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));
    const [showCreateSprintModal, setShowCreateSprintModal] = useState(false);
    useEffect(() => {
        checkRoleToAddCampaign();
    }, [projects]);

    const checkRoleToAddCampaign = () => {
        if (isAdmin(userInfo)) {
            return true;
        }
        for (let index = 0; index < projects.length; index++) {
            if (projects[index].ownerID == userInfo._id) {
                return true;
            }
        }
        return false;
    };
    return (
        <div className="dashboard-sidebar">
            <CheckTaskCreated onCreatedTask={() => setShowCreateTaskModal(false)} />
            <div className="add-new-task-btn-container">
                {!sprintID && (
                    <Button
                        variant="contained"
                        style={{ width: "100%", marginTop: "10px" }}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Back
                    </Button>
                )}
                <UserProgressUpdateModal />
                {!backButton && isAdmin(userInfo) && (
                    <Button variant="contained" onClick={() => setShowCreateSprintModal(true)}>
                        Thêm Sprint
                    </Button>
                )}
                {showCreateSprintModal && <CreateSprintModal onHide={() => setShowCreateSprintModal(false)} />}
                {!backButton && (
                    <Button variant="contained" onClick={() => setShowCreateTaskModal(true)}>
                        Thêm Task
                    </Button>
                )}
                {showCreateTaskModal && (
                    <CreateTaskModal onHide={() => setShowCreateTaskModal(false)} currentSprintID={sprintID} />
                )}
                {!backButton && checkRoleToAddCampaign() && (
                    <Button variant="contained" onClick={() => setShowCreateCampaignModal(true)}>
                        Thêm Campaign
                    </Button>
                )}
                {showCreateCampaignModal && (
                    <CreateCampaignModal
                        onHide={() => setShowCreateCampaignModal(false)}
                        currentSprintId={sprintID}
                        campaign={new Campaign({})}
                    />
                )}
                {isAdmin(userInfo) && <div>{currentSprint?.activeSprint && <EndSprintPanel />}</div>}
            </div>
            {!backButton && (
                <a
                    href={"/reports"}
                    onClick={() => {
                        history.push({ pathname: `/reports` });
                    }}
                    className={pageName === Constants.REPORTS ? "active" : ""}
                >
                    <span>Reports</span>
                </a>
            )}
            {!backButton && (
                <a
                    href={"/releases"}
                    onClick={() => {
                        history.push({ pathname: `/releases` });
                    }}
                    className={pageName === Constants.RELEASES ? "active" : ""}
                >
                    <span>Releases</span>
                </a>
            )}
            {!backButton && (
                <a
                    href={`/recruitment-page`}
                    onClick={() => {
                        history.push({ pathname: `/recruitment-page` });
                    }}
                    className={pageName === Constants.RECRUITMENT ? "active" : ""}
                >
                    <span>Recruitment</span>
                </a>
            )}
            {!backButton && (
                <a
                    href={`/info-recuiment-page`}
                    onClick={() => {
                        history.push({ pathname: `/info-recuiment-page` });
                    }}
                    className={pageName === Constants.INFORECRUITMENT ? "active" : ""}
                >
                    <span>InfoRecruitment</span>
                </a>
            )}
            {!backButton && (
                <a
                    href={"/tool"}
                    onClick={() => {
                        history.push({ pathname: `/tool` });
                    }}
                    className={pageName === Constants.TOOL ? "active" : ""}
                >
                    Tool
                </a>
            )}
            {listSprints.map((sprint: ISprint) => (
                <a
                    href={"/sprint/" + (sprint.shortId ? sprint.shortId : sprint._id)}
                    key={sprint._id}
                    className={sprint?.activeSprint ? "active" : ""}
                >
                    <span>{sprint.name}</span>
                    {sprint.activeSprint && <span> (active)</span>}
                    {!sprint.backLog && (
                        <p>
                            {workingDaysBetweenDates(sprint.startDate, sprint.endDate)} ngày (
                            {moment(sprint.startDate).format("MMM D")} - {moment(sprint.endDate).format("MMM D")})
                        </p>
                    )}
                </a>
            ))}
        </div>
    );
};

export const CheckTaskCreated: FC<{ onCreatedTask: () => void }> = ({ onCreatedTask }) => {
    const webState: IWebState = useSelector((state: any) => state.webReducer);
    const taskUpdated = webState.task;
    const toastCreateTask = (url: string) => {
        return (
            <div className="task-toastify">
                <p>Một task mới vừa được tạo</p>
                <a href={url}>Mở</a>
            </div>
        );
    };
    useEffect(() => {
        if (taskUpdated) {
            onCreatedTask();
            setTimeout(() => {
                const url = "/task/" + taskUpdated._id;
                toast(toastCreateTask(url), {
                    position: "top-left",
                });
            }, 500);
        }
    }, [taskUpdated]);

    return <></>;
};

export const EndSprintPanel = () => {
    const myProfile = useSelector((state: any) => state.authState.user);
    const currentSprint: ISprint | undefined = useSelector((state: any) =>
        state.sprintState.sprints.find((s: any) => s.activeSprint)
    );
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos);
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints);
    const allStatus: IStatus[] = useSelector((state: any) => state.statusState.status);
    const projects: IProject[] = useSelector((state: any) => state.projectState.projects);
    const dispatch = useDispatch();
    const [updateScore, setUpdateScore] = useState(false);
    const [open, setOpen] = useState(false);
    if (!isAdmin(myProfile)) {
        return null;
    }
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className="end-sprint">
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setOpen(true);
                }}
                className="btn-open-create-task-modal radius-12"
            >
                Kết thúc sprint
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title2"
                aria-describedby="alert-dialog-description2"
            >
                <DialogTitle id="alert-dialog-title2">Bạn có muốn kết thúc sprint này</DialogTitle>
                <DialogActions>
                    <Button
                        disabled={updateScore}
                        onClick={async () => {
                            if (currentSprint) {
                                setUpdateScore(true);
                                // let tasks = await getAllTaskAPI(
                                //     currentSprint._id
                                // );
                                // if (tasks && tasks.length) {
                                //     dispatch(getAllTaskSuccess(tasks)); // lay task cho sprint-report
                                //     updateUserScoreAPI(
                                //         currentSprint,
                                //         true,
                                //         tasks
                                //     ).then((data) => {
                                //         toast(
                                //             "Trang sẽ được reload sau giây lát...",
                                //             {
                                //                 position: "top-center",
                                //                 type: "success",
                                //                 autoClose: false,
                                //             }
                                //         );
                                //         dispatch({
                                //             type: "END_SPRINT",
                                //         });
                                //         setUpdateScore(false);
                                //         handleClose();
                                //     });
                                // }
                                updateUserScoreAPI(currentSprint, users, sprints, allStatus, projects, true).then((data) => {
                                    toast("Trang sẽ được reload sau giây lát...", {
                                        position: "top-center",
                                        type: "success",
                                        autoClose: false,
                                    });
                                    dispatch({
                                        type: "END_SPRINT",
                                    });
                                    setUpdateScore(false);
                                    handleClose();
                                });
                            }
                        }}
                        autoFocus
                    >
                        {updateScore ? "Đang cập nhật điểm" : "Kết thúc"}
                    </Button>
                    <Button onClick={handleClose}>Hủy</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DashboardSidebar;
