import { toast } from "react-toastify";
import { getPercentRealPrice, OPTIONS_PLATFORM } from "../config_data_analytic";
import { priceProApp } from "../config_web";
import Constants from "../constant";
import Config from "../shared/models/config";
import moment from "moment-timezone";
import { Sprint } from "../shared/models/sprint";
import user from "../components/user";
export const getLeadDev = (users) => {
    return users ? users.filter((user) => user.status == Constants.STATUS_PUBLIC && isLeadDev(user)) : [];
};

export const getStaff = (users) => {
    return users ? users.filter((user) => user.status == Constants.STATUS_PUBLIC && !isPartner(user)) : [];
};

export const isDev = (user) => {
    return user?.position && user.position.toLowerCase().indexOf("developer") > -1;
};

export const isDesign = (user) => {
    return user?.position && user.position.toLowerCase().indexOf("design") > -1;
};

export const isLeadDev = (user) => {
    return user?.position && user.position.toLowerCase().indexOf("lead dev") > -1;
};

export const isPartner = (user) => {
    return user?.role ? user.role === Constants.USER_ROLE_PARTNER : false;
};

export const isStaff = (user) => {
    return user?.role ? user.role === Constants.USER_ROLE_STAFF : false;
};

export const isAdmin = (user) => {
    return user?.role ? user.role === Constants.USER_ROLE_ADMIN : false;
};

export const isCreateBlog = (user) => {
    return user?.role ? user.role === Constants.USER_ROLE_CREATE_BLOG : false;
};

export const isLeader = (user) => {
    return user?.role ? user.role === Constants.USER_ROLE_LEADER : false;
};

export const getTester = (users) => {
    return users
        ? users.filter(
              (user) =>
                  user.status == Constants.STATUS_PUBLIC && user.position && user.position.toLowerCase().indexOf("tester") > -1
          )
        : [];
};

export const getAdmin = (users) => {
    return users ? users.filter((user) => user.role === Constants.USER_ROLE_ADMIN) : [];
};

//get leader cua user
export const getLeaderGroup = (userGroups, user, allUsers) => {
    let leaderListIds = [];
    //find list of leader's ids
    for (let group of userGroups) {
        let usr = group.users.find((u) => u.userId === user._id);
        if (usr) {
            leaderListIds = leaderListIds.concat(group.users.filter((usr) => usr.role === "lead").map((usr) => usr.userId));
        }
    }
    //find list of all leader's info based on their id
    let leaderList = [];
    for (let leaderId of leaderListIds) {
        let leaderInfo = allUsers.filter((usr) => usr._id === leaderId);
        leaderList = [...leaderList, ...leaderInfo];
    }
    return leaderList;
};
// to prevent duplicate an user who have both admin & lead role
export const getSetOfAllLeader = (leader, listAdmin) => {
    let result = [...leader, ...listAdmin];
    return [...new Map(result.map((item) => [item["_id"], item])).values()];
};

export const delay = (waitTimeInMs) => new Promise((resolve) => setTimeout(resolve, waitTimeInMs));
export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const formatDate = (date, format = "default") => {
    if (!date) {
        return "";
    }
    var year = date.getFullYear();
    var months = date.getMonth() + 1;
    var day = date.getDate();
    var stringTime = "";
    var stringMonths = "";
    var stringDay = "";
    if (months < 10) {
        stringMonths = "0" + months.toString();
    } else {
        stringMonths = months.toString();
    }
    if (day < 10) {
        stringDay = "0" + day.toString();
    } else {
        stringDay = day.toString();
    }
    if (format === "dd/mm/yyyy") {
        stringTime = stringDay + "/" + stringMonths + "/" + year;
    } else if (format === "yyyy/mm/dd") {
        stringTime = year + "/" + stringMonths + "/" + stringDay;
    } else {
        stringTime = year + "-" + stringMonths + "-" + stringDay;
    }
    return stringTime;
};

export const convertDateToTimestamp = (date) => {
    let dateObj = new Date(date);
    dateObj.setHours(0);
    dateObj.setMinutes(0);
    dateObj.setSeconds(0);
    return dateObj.getTime();
};

export const formatNumber = (number, metric = null, type = null) => {
    number = parseFloat(number);
    if (metric === "ga:avgSessionDuration") {
        number = number / 60;
    }
    if (number % 1 !== 0) {
        number = number.toFixed(2);
    }
    if (type === "label") {
        return formatResultByMetric(metric, number);
    }
    return number;
};

export const formatResultByMetric = (metric, result) => {
    if (metric === "ga:bounceRate" || metric === "returningUsers") {
        return (result += " %");
    } else if (metric === "ga:avgSessionDuration") {
        return Math.floor(result) + "m" + parseInt(60 * (result - Math.floor(result))) + "s";
    } else if (metric === "revenue") {
        return "$" + result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const lightenDarkenColor = (color, percent) => {
    let usePound = false;

    if (color[0] == "#") {
        color = color.slice(1);
        usePound = true;
    }

    const num = parseInt(color, 16);

    let r = (num >> 16) + percent;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + percent;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + percent;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

export const formatDateToDayAndMonth = (date, hasWeekDay = false, hasMonth = false) => {
    const day = date.substr(6, 2);
    const month = date.substr(4, 2);
    const year = date.substr(0, 4);
    const weekDay = new Date(year + "-" + month + "-" + day).toLocaleDateString("en-US", { weekday: "short" });
    if (hasWeekDay) {
        return weekDay + " " + day + "/" + month;
    }
    if (hasMonth) {
        let monthString = new Date(year + "-" + month + "-" + day).toLocaleDateString("en-US", { month: "short" });
        return monthString + " " + day;
    }
    return day + "/" + month;
};

export const getWeekFromDate = (date) => {
    let week = Math.ceil(date / 7);
    if (week > 4) {
        week = 4;
    }
    return week;
};

export const checkPermissionChangeTask = ({ user, ownerID, testerID, task, nextColumn }) => {
    if (!user) {
        return;
    }
    const isAdmin = user.role == Constants.USER_ROLE_ADMIN;

    // Các thành viên ko phải assignee hoặc reporter thì ko được quyền thay đổi task.
    let assignee = task.assignee && user._id == task.assignee._id; // assignee
    let reporter = task.reporter && user._id == task.reporter._id; //  reporter

    // PO được phép thay đổi status sang mọi giá trị.
    let po = ownerID && ownerID == user._id;

    // Lead dev được phép thay đổi status sang in progress, in code review, to deploy, prod validation.
    let leadDev = (task.leadDev && task.leadDev._id == user._id) || isLeadDev(user);

    // Tester được phép thay đổi status sang in progress, in code review.
    let tester = (task.tester?._id ?? testerID) == user._id;
    if (task.testers) {
        const testerFound = task.testers.indexOf(user._id) > -1;
        tester = tester || testerFound;
    }

    if (isAdmin || assignee || reporter || po || leadDev || tester) {
        let _hasPer = isAdmin || reporter || po;
        if (
            !nextColumn ||
            _hasPer ||
            nextColumn.title.toUpperCase() == Constants.STATUS_TASK_TO_DO ||
            checkPermissionChangeColumn({
                assignee,
                leadDev,
                nextColumn,
                tester,
                task,
                user,
            })
        ) {
            return true;
        }
        return false;
    } else {
        toast("Bạn không có quyền thay đổi!", {
            position: "top-center",
        });
        return false;
    }
};

const checkPermissionChangeColumn = ({ assignee, leadDev, tester, nextColumn, task, user }) => {
    let roleForTester = [Constants.STATUS_TASK_REVIEW_CODE, Constants.STATUS_TASK_IN_PROGRESS];
    let roleForLeadDev = [...roleForTester, Constants.STATUS_TASK_TO_DEPLOY, Constants.STATUS_TASK_PROD_VALIDATION];
    let roleForAssignee = [Constants.STATUS_TASK_TO_DO, Constants.STATUS_TASK_IN_PROGRESS, Constants.STATUS_TASK_QA_TESTING];
    let hasPermission =
        (assignee && roleForAssignee.indexOf(nextColumn.title.toUpperCase()) > -1) ||
        (leadDev && roleForLeadDev.indexOf(nextColumn.title.toUpperCase()) > -1) ||
        (tester && roleForTester.indexOf(nextColumn.title.toUpperCase()) > -1);

    const customId = "custom-toast-id";

    if (hasPermission) {
        let moveTaskToToDoColumn = nextColumn.title.toUpperCase().indexOf(Constants.STATUS_TASK_TO_DO) > -1;
        let point = task.point;
        if (typeof point != "number") {
            try {
                point = parseInt(task.point);
            } catch (e) {}
        }
        let checkHasPoint = moveTaskToToDoColumn || (typeof point == "number" && point >= 0) || !!task.finishedDate;
        if (checkHasPoint) {
            return true;
        }
        toast('Cần cập nhật số point cho task: "' + task.title + '" trước khi chuyển!', {
            position: "top-center",
            toastId: customId,
        });
        return false;
    }
    toast("Bạn không có quyền thay đổi sang " + nextColumn.title.toUpperCase() + "!", {
        position: "top-center",
    });
    return false;
};

export function getFullName(user) {
    if (user) {
        return user.lastName + " " + user.firstName;
    }
    return "";
}

export const getTimeFromStartTask = (startDateProp, endDateProp) => {
    if (!startDateProp) {
        return;
    } else if (!endDateProp) {
        const nowDate = new Date();
        const startDate = new Date(startDateProp);
        const secondsNowDate = nowDate.getTime() / 86400000;
        const secondsStartDate = startDate.getTime() / 86400000;
        return Math.floor(secondsNowDate - secondsStartDate);
    } else {
        const startDate = new Date(startDateProp);
        const endDate = new Date(endDateProp);
        const secondsStartDate = startDate.getTime() / 86400000;
        const secondsEndDate = endDate.getTime() / 86400000;
        return Math.ceil(secondsEndDate - secondsStartDate);
    }
};

export const convertLink = (html) => {
    if (!html) {
        return;
    }
    var expression = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/gi;
    var matches = html.match(expression);
    for (let i in matches) {
        let href = matches[i];
        let prevPosition = html.indexOf(href) - 1;
        if (prevPosition > -1 && html.charAt(prevPosition) !== '"') {
            html = html.replace(href, `<a class="link">${href}</a>`);
        }
    }
    return html;
};

export const getPriority = (id) => {
    switch (id) {
        case Constants.LOWEST:
            return "Lowest";
        case Constants.LOW:
            return "Low";
        case Constants.MEDIUM:
            return "Medium";
        case Constants.HIGH:
            return "High";
        case Constants.HIGHEST:
            return "Highest";
        default:
            return;
    }
};

export const getPriorityColor = (id) => {
    switch (id) {
        case Constants.LOWEST:
            return "#4caf50";
        case Constants.LOW:
            return "#ffeb3b";
        case Constants.MEDIUM:
            return "#ff9800";
        case Constants.HIGH:
            return "#E2252B";
        case Constants.HIGHEST:
            return "#9A0E03";
        default:
            return;
    }
};

export const generateString = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz/.,;!@#$%^&*0123456789";
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const workingDaysBetweenDates = (d0, d1) => {
    const millisecondsPerDay = 86400 * 1000;

    const day0 = new Date(d0).setHours(0, 0, 0, 1);
    const day1 = new Date(d1).setHours(23, 59, 59, 999);

    const startDate = new Date(day0).getTime();
    const endDate = new Date(day1).getTime();
    if (startDate > endDate) {
        return 0;
    }

    let diff = endDate - startDate;
    let days = Math.ceil(diff / millisecondsPerDay);
    let weeks = Math.floor(days / 7);
    days -= weeks * 2;

    let startDay = new Date(d0).getDay();
    let endDay = new Date(d1).getDay();

    if (startDay - endDay > 1) {
        days -= 2;
    }
    if (startDay == 0 && endDay != 6) {
        days--;
    }
    if (endDay == 6 && startDay != 0) {
        days--;
    }

    return days;
};

export const formatCurrency = (number) => {
    return new Intl.NumberFormat("en-IN", {
        maximumSignificantDigits: 3,
    }).format(number);
};

export const getDayofWeek = (date, stringMonth = false, stringYear = false) => {
    const d = new Date(date);
    let day = d.getDay();
    let getDate = d.getDate();
    let today = new Date().getDate();
    // let year = new Date().getFullYear();
    // let month = d.getMonth() + 1;

    let dayOfWeek = d.toLocaleDateString("en-US", {
        weekday: "short",
    });
    if (today === getDate) {
        dayOfWeek = "Today";
    }
    if (stringMonth) {
        let monthString = d.toLocaleDateString("en-US", { month: "short" });

        if (stringYear) {
            return monthString + " " + getDate + ", " + d.getFullYear();
        }
        return monthString + " " + getDate;
    }
    return dayOfWeek;
};

export const hasAppReactNative = (certificates) => {
    const rns = [Constants.PLATFORM_REACT_NATIVE_ANDROID, Constants.PLATFORM_REACT_NATIVE_IOS];
    let appRNs = certificates.appInfos?.find((app) => rns.includes(app.platformId));
    return appRNs;
};

export const hasAppFlutter = (certificates) => {
    const rns = [Constants.PLATFORM_FLUTTER_ANDROID, Constants.PLATFORM_FLUTTER_IOS];
    let appRNs = certificates.appInfos?.find((app) => rns.includes(app.platformId));
    return appRNs;
};

export const hasAppWeb = (certificates) => {
    return true;
    // return !certificates.parent?.length;
};

export const hasAppFlutterAndroid = (certificates) => {
    const p = [Constants.PLATFORM_FLUTTER_ANDROID];
    let appRNs = certificates.appInfos?.find((app) => p.includes(app.platformId));
    return appRNs;
};

export const hasAppFlutterIOS = (certificates) => {
    const p = [Constants.PLATFORM_FLUTTER_IOS];
    let appRNs = certificates.appInfos?.find((app) => p.includes(app.platformId));
    return appRNs;
};

export const hasAppRNAndroid = (certificates) => {
    const p = [Constants.PLATFORM_REACT_NATIVE_ANDROID];
    let appRNs = certificates.appInfos?.find((app) => p.includes(app.platformId));
    return appRNs;
};

export const hasAppRNIOS = (certificates) => {
    const p = [Constants.PLATFORM_REACT_NATIVE_IOS];
    let appRNs = certificates.appInfos?.find((app) => p.includes(app.platformId));
    return appRNs;
};
export const formatDataProRevenueApp = (resDataProApp, certificates) => {
    const appPrices = {};
    certificates?.appInfos?.forEach((app) => {
        if (app.platformId == 2) {
            appPrices["android"] = app.price;
        } else if (app.platformId == 3) {
            appPrices["ios"] = app.price;
        }
    });
    let formatResDataProAppIos = {};
    let formatResDataProAppAndroid = {};
    Object.keys(resDataProApp).forEach((key) => {
        const appData = resDataProApp[key];
        appData.forEach((item) => {
            let month = String(new Date(item.createDate).getMonth() + 1);
            if (month.length < 2) {
                month = "0" + month;
            }
            let priceConfigApp = appPrices[item.osType] ?? 0;
            if (priceConfigApp == 0) {
                priceConfigApp = priceProApp()[item.appId] ?? 0;
            }
            if (item.osType == OPTIONS_PLATFORM[1]) {
                let sum = formatResDataProAppAndroid[month] ?? 0;
                sum += priceConfigApp;

                // sum = Number(sum) + Number(item.price);
                formatResDataProAppAndroid[month] = sum;
            } else {
                let sum = formatResDataProAppIos[month] ?? 0;
                sum += priceConfigApp * getPercentRealPrice(item.osType);
                // sum = Number(sum) + Number(item.price);
                formatResDataProAppIos[month] = sum;
            }
        });
    });
    return { formatResDataProAppIos, formatResDataProAppAndroid };
};
export const groupArrayOfObjects = (list, key) => {
    if (!list) {
        return {};
    }
    return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const formatPrice = (number) => {
    if (number) {
        let result = Number(number)
            ?.toFixed(0)
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return result ?? 0;
    }

    return 0;
};
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function hasRevenue(appName) {
    if (appName == "worksheetzone" || appName == "usmle") {
        return false;
    }
    return true;
}
export const getDateTime = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    return date.getTime();
};
export const uniqueArray = (arr) => {
    const uniqueIds = [];

    const unique = arr.filter((element) => {
        const isDuplicate = uniqueIds.includes(JSON.stringify(element));

        if (!isDuplicate) {
            uniqueIds.push(JSON.stringify(element));

            return true;
        }

        return false;
    });

    return unique;
};

export const converDataCampaign = (campaigns) => {
    let setIds = new Set();
    let campaignObj = campaigns.map((c) => {
        if (c.listTaskIDs?.length) {
            c?.listTaskIDs?.forEach((t) => {
                setIds.add(t);
            });
        }
        return {
            campaignId: c._id,
            listTask: c?.listTaskIDs ?? [],
        };
    });
    return {
        listTaskIds: Array.from(setIds),
        campaignObj: campaignObj,
    };
};

export const checkRequirementsChangeStatusField = (task, nextColumnId) => {
    if (task?.point === undefined || task?.point === null) {
        toast(() => <div>Chưa có point!</div>, {
            position: "top-center",
            type: "error",
        });
        return false;
    }
    // if (!!!task.deadline) {
    //     toast(() => <div>The deadline must be set!</div>, {
    //         position: "bottom-center",
    //         type: "error",
    //     });
    //     return false;
    // }
    if (task.statusId === Config.COLUMN_STATUS.DONE.mongoId) {
        toast(() => <div>Không thể thay đổi trạng thái của task DONE!</div>, {
            position: "top-center",
            type: "error",
        });
        return false;
    }
    if (
        task.projectId == Config.PROJECT_ID_WORKSHEET &&
        nextColumnId == Config.COLUMN_STATUS.QA_TESTING.mongoId &&
        !task.testLink
    ) {
        toast(() => <div>Task thuộc project Worksheet phải có test link!</div>, {
            position: "top-center",
            type: "error",
        });
        return false;
    }
    return true;
};

export const timezoneSetHours = (date, h = 0, m = 0, s = 0) => {
    //*** neu co update thi chu y update ca ben server trong utils */
    try {
        let _date = moment(date).tz(Config.TIMEZONE).set({ hour: h, minute: m, second: s }).format();
        return new Date(_date);
    } catch (e) {
        console.log(e);
        return new Date();
    }
};

export const getDate = (date) => {
    try {
        return moment(date).tz(Config.TIMEZONE).format().substring(0, 10); //YYYY-MM-DDThh:mm:ss+07:00
    } catch (e) {
        console.log(e);
        return moment(new Date()).tz(Config.TIMEZONE).format().substring(0, 10); //YYYY-MM-DDThh:mm:ss+07:00
    }
};
