import Grid from "@material-ui/core/Grid";
import { FC, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
// import { Calendar } from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateTask } from "../../../../redux/actions/task.action";
import Config from "../../../../shared/models/config";
import { IProject } from "../../../../shared/models/project";
import { ISprint } from "../../../../shared/models/sprint";
import { ITask, Task } from "../../../../shared/models/task";
import { IUserInfo } from "../../../../shared/models/userInfo";
import EditorWidget from "../../../ckeditor/editor-widget";
import MyTextBox from "../../widgets/MyTextBox";
import SelectProjectWidget from "../edit-task-modal/select-project";
import SelectSprintWidget from "../edit-task-modal/select-sprint";
import SelectUserWidget from "../edit-task-modal/select-user";
import SelectCampaignsWidget from "./select-campaign";
import "./style.scss";
import moment from "moment";
import FormControl from "react-bootstrap/FormControl";
// import { IUserGroup } from "../../../../shared/models/userGroup";

const getMapReporterDefault = () => {
    const mapReporterDefault = new Map<string, string>();
    mapReporterDefault.set("app", "6157c7cce40d4cf6e89ff7de"); // Hiệp
    mapReporterDefault.set("web", "6157c835e40d4cf6e89ff801"); // Hoàng
    mapReporterDefault.set("operation", "6157c7cce40d4cf6e89ff7de"); // Hiệp
    mapReporterDefault.set("worksheet", "6157c696e40d4cf6e89ff757"); // Trường
    mapReporterDefault.set("marketing", "6157c763e40d4cf6e89ff7b6"); // Dung
    mapReporterDefault.set("content", "618b6a519476475972205522"); // Đăng
    mapReporterDefault.set("dashboard", "6157c835e40d4cf6e89ff801"); // Hoàng
    return mapReporterDefault;
};

const getMapLeadDevDefault = () => {
    const map = new Map<string, string>();
    map.set("app", "6157c7cce40d4cf6e89ff7de");
    map.set("web", "6157c835e40d4cf6e89ff801");
    map.set("operation", "6157c7cce40d4cf6e89ff7de");
    map.set("worksheet", "6157c835e40d4cf6e89ff801");
    return map;
};

const getMapTesterDefault = () => {
    const map = new Map<string, string>();
    // map.set("app", "63f8795767a8f37fe11d5d7c"); // Thảo Ngô
    map.set("web", "63f8795767a8f37fe11d5d7c"); // Thảo Ngô
    // map.set("operation", "638e9f0af21ce214af4e4934"); // Dung Phạm
    map.set("worksheet", "638e9f0af21ce214af4e4934"); // Dung Phạm

    map.set("app", "64910c1373fdc8f8fabda963"); // Đặng Hạnh
    map.set("operation", "64910c1373fdc8f8fabda963"); // Đặng Hạnh

    return map;
};

type TaskType = "Task" | "Bug";

const CreateTaskModal: FC<{
    currentSprintID: string;
    onHide: () => void;
}> = ({ currentSprintID, onHide }) => {
    const titleRef = useRef<HTMLInputElement | null>(null);
    const pointRef = useRef<HTMLInputElement | null>(null);
    const priorityRef = useRef<HTMLSelectElement | null>(null);
    const coefficientRef = useRef<HTMLSelectElement | null>(null);
    const typeRef = useRef<HTMLSelectElement | null>(null);
    const sprintRef = useRef<HTMLSelectElement | null>(null);
    const [deadline, setDeadline] = useState<Date | null>(null);
    const [projectId, setProjectId] = useState("");
    const [assigneeId, setAssigneeId] = useState("");
    const [reporterId, setReporterId] = useState("");
    const [description, setDescription] = useState("");
    const [testerIds, setTesterIds] = useState<string[]>([]);
    const [informIds, setInformIds] = useState<string[]>([]);
    const [leadDevId, setLeadDevId] = useState("");
    const [campaignIds, setCampaignIds] = useState<string[]>([]);
    const taskTypes: TaskType[] = ["Task", "Bug"];
    const dispatch = useDispatch();
    const projects: IProject[] = useSelector((state: any) => state.projectState.projects ?? []);
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos ?? []);
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
    const processing: boolean = useSelector((state: any) => state.taskState.processing);
    const mapUser: Map<string, IUserInfo> = new Map();
    for (let user of users) {
        mapUser.set(user._id, user);
    }
    const mapProject: Map<string, IProject> = new Map();
    for (let project of projects) {
        mapProject.set(project._id, project);
    }
    const mapSprint: Map<string, ISprint> = new Map();
    for (let sprint of sprints) {
        mapSprint.set(sprint._id, sprint);
    }
    const getListUser = (ids: string[]) => {
        const list: IUserInfo[] = [];
        for (let id of ids) {
            const user = mapUser.get(id);
            if (user) {
                list.push(user);
            }
        }
        return list;
    };

    const onCreateTask = () => {
        let point: number | undefined;
        if (pointRef.current?.value) {
            point = parseInt(pointRef.current?.value ?? "");
        }
        if (point && point > 8) {
            toast(() => <div>Point phải &le; 8 hoặc chia nhỏ task!</div>, {
                position: "top-center",
                type: "error",
            });
            return;
        }
        const _sprintId = sprintRef.current?.value ?? currentSprintID;

        const newTask: ITask = new Task({
            assigneeId,
            assignee: mapUser.get(assigneeId),
            deadline,
            description,
            inform: informIds,
            priority: priorityRef.current?.value ?? "",
            leadDevId,
            leadDev: mapUser.get(leadDevId),
            point: point,
            projectId,
            project: mapProject.get(projectId),
            reporterId,
            reporter: mapUser.get(reporterId),
            testerIds,
            testers: getListUser(testerIds),
            sprintId: _sprintId,
            sprint: mapSprint.get(_sprintId),
            type: typeRef.current?.value ?? "Task",
            title: titleRef.current?.value ?? "",
            createdTime: new Date(),
            statusView: 1,
            coef: parseFloat(coefficientRef.current?.value ?? "1"),
        });
        if (!newTask.title) {
            toast("Chưa có tiêu đề!", { position: "top-center" });
            return;
        }
        if (!newTask.projectId) {
            toast("Chưa có project!", { position: "top-center" });
            return;
        }
        if (!newTask.reporterId) {
            toast("Chưa có reporter!", { position: "top-center" });
            return;
        }
        if (newTask.project?.title === "Worksheet" && testerIds.length === 0) {
            toast("Chưa có tester!", { position: "top-center" });
            return;
        }
        dispatch(updateTask(newTask, campaignIds));
    };
    useEffect(() => {
        const project = mapProject.get(projectId);
        if (project) {
            const _reporterId: string | undefined = getMapReporterDefault().get(project.title.toLowerCase());
            if (_reporterId) {
                setReporterId(_reporterId);
            } else {
                setReporterId("");
            }
            const _leadDevId: string | undefined = getMapLeadDevDefault().get(project.title.toLowerCase());
            if (_leadDevId && _reporterId != _leadDevId) {
                setLeadDevId(_leadDevId);
            } else {
                setLeadDevId("");
            }
            const _testerId: string | undefined = getMapTesterDefault().get(project.title.toLowerCase());
            if (_testerId && _testerId != _leadDevId && _testerId != _reporterId) {
                setTesterIds([_testerId]);
            } else {
                setTesterIds([]);
            }
        }
    }, [projectId]);

    return (
        <Modal show={true} size="xl" centered onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Tạo Task Mới</Modal.Title>
            </Modal.Header>
            <ModalBody className="create-task-modal">
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={7}>
                        <div className="item title">
                            <h5>Tiêu đề (*)</h5>
                            <MyTextBox ref={titleRef} text={""} title="title" />
                        </div>
                        <div className="item description">
                            <div className="description-line-1">
                                <h5>Mô tả</h5>
                            </div>
                            <EditorWidget
                                defaultValue={description}
                                buttonSave={false}
                                onChange={(value) => setDescription(value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <div className="item item-inline type">
                            <h5>Type</h5>
                            <Form.Select ref={typeRef} defaultValue={taskTypes[0]}>
                                {taskTypes.map((type, index) => (
                                    <option key={index} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className="item item-inline project">
                            <h5>Projects (*)</h5>
                            <SelectProjectWidget ids={[projectId]} onSelected={(value) => setProjectId(value[0])} />
                        </div>
                        {projectId && (
                            <>
                                <div className="item item-inline assignee">
                                    <h5>Assignee</h5>
                                    <SelectUserWidget
                                        ids={[assigneeId]}
                                        onSelected={(value) => setAssigneeId(value[0])}
                                        // type={"assignee"}
                                    />
                                </div>
                                <div className="item item-inline reporter">
                                    <h5>Reporter (*)</h5>
                                    <SelectUserWidget
                                        ids={[reporterId]}
                                        onSelected={(value) => setReporterId(value[0])}
                                        require={true}
                                    />
                                </div>
                            </>
                        )}
                        <div className="item item-inline sprint">
                            <h5>Sprint</h5>
                            <SelectSprintWidget ref={sprintRef} sprintId={currentSprintID} />
                        </div>
                        <div className="item item-inline priority">
                            <h5>Priority</h5>
                            <Form.Select ref={priorityRef} defaultValue={""}>
                                {["", ...Object.values(Config.PRIORITY_POOL)].map((p, index) => (
                                    <option key={index} value={p}>
                                        {p}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className="item item-inline campaign">
                            <h5>Campaigns</h5>
                            <SelectCampaignsWidget ids={campaignIds} onChange={setCampaignIds} />
                        </div>
                        {projectId && (
                            <>
                                <div className="item item-inline tester">
                                    <h5>Tester</h5>
                                    <SelectUserWidget
                                        require={false}
                                        isMulti={true}
                                        ids={testerIds}
                                        onSelected={(value) => setTesterIds(value)}
                                    />
                                </div>
                                <div className="item item-inline leaddev">
                                    <h5>Lead Dev</h5>
                                    <SelectUserWidget
                                        ids={[leadDevId]}
                                        onSelected={(value) => setLeadDevId(value[0])}
                                        type={"leaddev"}
                                    />
                                </div>
                                <div className="item item-inline inform">
                                    <h5>Inform</h5>
                                    <SelectUserWidget
                                        isMulti={true}
                                        ids={informIds}
                                        onSelected={(value) => setInformIds(value)}
                                    />
                                </div>
                            </>
                        )}
                        <div className="item item-inline deadline">
                            <h5>Deadline</h5>
                            <FormControl
                                type="datetime-local"
                                name="deadline"
                                value={moment(deadline).format("YYYY-MM-DDTHH:mm")}
                                onChange={(e: any) => {
                                    setDeadline(new Date(e.target.value));
                                }}
                            />
                        </div>
                        <div className="item item-inline point">
                            <h5>Estimated Points</h5>
                            <MyTextBox ref={pointRef} text={""} type="number" min={0} title="Estimated Points" />
                        </div>
                        <div className="item item-inline coef">
                            <h5>Coefficient</h5>
                            <Form.Select ref={coefficientRef} defaultValue={"1"}>
                                {Object.values(Config.COEF).map((p, index) => (
                                    <option key={index} value={p}>
                                        {p}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Grid>
                </Grid>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCreateTask} disabled={processing} style={{ background: "#4bb4ed", borderColor: "#4bb4ed" }}>
                    Tạo
                </Button>
                <Button className="close" aria-label="Close" onClick={onHide} variant="secondary">
                    Huỷ
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreateTaskModal;
